import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-comentarios',
  templateUrl: './comentarios.component.html',
  styleUrls: ['./comentarios.component.scss'],
})
export class ComentariosComponent implements OnInit {

  constructor(private http:HttpClient) { }

  @Input() post:any;
  @Input() foto_src:any;
  @Output() comentario = new EventEmitter();
  @Output() reaction = new EventEmitter();
  @Output() delete = new EventEmitter();
  @Output() denuncia = new EventEmitter();
  @Output() fechar = new EventEmitter();
  user_id;

  carregando: boolean = false;

  ngOnInit() {
    this.user_id = localStorage.getItem('userId');
  }


  toggleReactions(post: any): void {
    post.showReactions = !post.showReactions;
  }

  toggleComments(post: any): void {
    post.showComments = !post.showComments;
  }

  togglePostComments(post: any): void {
    post.showPostComments = !post.showPostComments;
  }

  toggleCommentReactions(comment: any): void {
    comment.showReactions = !comment.showReactions;
  }

  toggleDenunciation(content: any): void {
    content.showOptions = !content.showOptions;
  }

  comentar($event, content, type){
    this.carregando = true;
    setTimeout(()=>{ this.carregando = false }, 2000);
    this.comentario.emit({$event,content,type});
  }

  deletar(content){
    this.delete.emit({content});
  }

  reactComment(comment, type){
    this.reaction.emit({comment, type});
    let efeito_sonoro = new Audio("assets/audio/react.wav");
    efeito_sonoro.play(); 
  }

  denunciar(comment){
    this.denuncia.emit({comment});
  }

  close(){
    this.fechar.emit();
  }


}
