import { Component, Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
}) @Injectable()

export class HeaderComponent {

  foto_src =  "https://cdn-icons-png.flaticon.com/512/149/149071.png";

  modalNotificacoes = false;
  home = '/home'

  user_id:any;
  token:any;
  quiz:any = [];
  perguntas:any;
  safeURL:any;
  respostas:any = [];
  acertou:any;
  resposta_correta:any;
  tipo: string;
  date: any;

  notificacoes: any = []
  id_notificacao: any;
  titulo_notificacao: any;
  descricao_notificacao: any;
  data_inicio_notificacao: any; 
  link_notificacao: any;

  perfil = localStorage.getItem('perfil');

  admin:any;

  quizesLista: any = [];
  notificacoesLista: any = [];

  contadorNotificacoes: number = 0;

  foto_user:any = "https://cdn-icons-png.flaticon.com/512/149/149071.png";  
  quiz_id:any;

  constructor(private http: HttpClient, private sanitizer: DomSanitizer, private router:Router, private route:ActivatedRoute) {
    this.set_link();
  }

  ngOnInit() {
    
    this.user_id = localStorage.getItem("userId");
    this.foto_user = localStorage.getItem("foto_src0");
  
    this.buscar_quiz();
    this.buscar_notificacoes();
    
    if(localStorage.getItem('foto_src')){
      this.foto_src = localStorage.getItem('foto_src');
    }

    if(localStorage.getItem('admin')){
      this.admin =  localStorage.getItem('admin');
        if(this.admin == '1'){
          this.admin = true;
        }
    }
  }
  
  set_link(){
    const width = window.innerWidth;

    if(width > 760){
      this.home = '/home-desktop';
    }
    else{
      this.home = '/home';
    }
  }

  actionModalNotificacoes() {
    this.modalNotificacoes = !this.modalNotificacoes;
  
    if (this.modalNotificacoes) {
      const formData = new FormData();
      formData.append('user_id', this.user_id); 
  
      this.http.post<any>(environment.API_URL.concat('notificacoes_iclub/marcar_todas_como_lidas'), formData).subscribe(
        response => {
          if (response.status === 1) {
            console.log('Todas as notificações foram marcadas como lidas.');
  
            this.notificacoes.forEach((notificacao: any) => {
              notificacao.is_read = '1';
            });
  
            this.contadorNotificacoes = 0;
          } else {
            console.error('Erro ao marcar todas as notificações como lidas:', response.message);
          }
        },
        error => {
          console.error('Erro na requisição:', error);
        }
      );
    }
  }
  
  
  
  getNotificationMessage(notificacao: any): string {
    const totalAtores = parseInt(notificacao.total_atores, 10) || 0;
    const actorNames = notificacao.actor_nomes ? notificacao.actor_nomes.split(',') : [];

    const messages = {
      'like_post': {
        singular: 'curtiu o seu post',
        plural: 'curtiram o seu post'
      },
      'like_comment': {
        singular: 'curtiu o seu comentário',
        plural: 'curtiram o seu comentário'
      },
      'comment': {
        singular: 'comentou no seu post',
        plural: 'comentaram no seu post'
      },
      'follow': {
        singular: 'começou a te seguir',
        plural: 'começaram a te seguir'
      }
    };
  
    const messageInfo = messages[notificacao.type];
  
    if (!messageInfo) {
      return notificacao.descricao || 'Você tem uma nova notificação.';
    }
  
    if (totalAtores === 1) {
      return `${actorNames[0]} ${messageInfo.singular}.`;
    } else if (totalAtores === 2) {
      return `${actorNames[0]} e ${actorNames[1]} ${messageInfo.plural}.`;
    } else if (totalAtores > 2) {
      const othersCount = totalAtores - 2;
      return `${actorNames[0]}, ${actorNames[1]} e mais ${othersCount} pessoas ${messageInfo.plural}.`;
    } else {
      return 'Você tem uma nova notificação.';
    }
  }
  
  changeToAdmin(){
    const id = localStorage.getItem('userId');

    this.http.post<any>(environment.API_URL.concat('/login_iclub/login_admin'), {id}).subscribe( (data) => {
      if(data.status == 1){
        localStorage.setItem('userName', data.user.nome);
        localStorage.setItem('userId', data.user.id);
        localStorage.setItem('token', data.token);
        localStorage.setItem('perfil', data.user.perfil);
        localStorage.setItem('foto_src', '/assets/iquine-photo.jpeg');

        if (data.user.foto) {
          let foto_src = environment.API_URL.replace('/api/', '/static/participantes/' + data.user.foto);
          localStorage.setItem('foto_src', foto_src);
        }
    
        this.set_link();
        this.router.navigate([this.home]).then( () => { location.reload(); });
      }
      else{
        alert('Houve um erro ao processar sua requisição');
      }
    })

  }

  changeToUser(){
    const nameUser = localStorage.getItem('userName0');
    const idUser = localStorage.getItem('userId0');
    const tokenUser = localStorage.getItem('token0');
    const perfilUser = localStorage.getItem('perfil0');
    const fotoUser = localStorage.getItem('foto_src0');

    localStorage.setItem('userName', nameUser);
    localStorage.setItem('userId', idUser);
    localStorage.setItem('token', tokenUser);
    localStorage.setItem('perfil', perfilUser);
    localStorage.setItem('foto_src', fotoUser);
    
    this.set_link();
    this.router.navigate([this.home]).then( () => { location.reload(); });

  }
 
  buscar_quiz() {
    this.http.get<any>(environment.API_URL.concat('quiz_iclub/index/' + this.user_id)).subscribe(data => {
      this.quiz = data.quiz;
      this.quiz_id = data.quiz.id;
      this.tipo = data.quiz.tipo;
      if(data.quiz){
        this.quiz.data_inicio = new Date(data.quiz.data_inicio);
      }
  
      const quizesNaoVisualizados = JSON.parse(localStorage.getItem('quizes_nao_visualizados')) || [];
  
      const quizNaoVisualizado = quizesNaoVisualizados.find(quiz => quiz.id === this.quiz.id && !quiz.respondido);
  
      if (!quizNaoVisualizado) {
        quizesNaoVisualizados.push({
          id: this.quiz.id,
          tipo: this.quiz.tipo,
          visualizado: false,
          date: this.quiz.data_inicio,
          respondido: false
        });
        localStorage.setItem('quizes_nao_visualizados', JSON.stringify(quizesNaoVisualizados));
      }
  
      this.quizesLista = quizesNaoVisualizados.filter(quiz => !quiz.respondido).slice(0, 3);
      this.updateNotificacoesCount();
    });
  }
  


  buscar_notificacoes() {
    if (!this.user_id) {
      console.error('User ID não está definido.');
      return;
    }

    const params = new HttpParams().set('user_id', this.user_id);

    this.http.get<any>(environment.API_URL.concat('notificacoes_iclub/obter_notificacoes'), { params }).subscribe(
      data => {
        if (data.status === 1 && data.notificacoes.length > 0) {
          // Pré-processa as notificações para adicionar a propriedade 'fotos'
          this.notificacoes = data.notificacoes.map((notificacao: any) => ({
            ...notificacao,
            fotos: this.processarFotos(notificacao.actor_fotos)
          }));

          this.contadorNotificacoes = this.notificacoes.filter((notificacao: any) => notificacao.is_read === '0').length;

          this.updateNotificacoesCount();
        } else {
          console.error('Nenhuma notificação encontrada na resposta da API.');
        }
      },
      error => {
        console.error('Erro na requisição:', error);
      }
    );
  }

  processarFotos(actor_fotos: string): string[] {
    const actorFotos = actor_fotos ? actor_fotos.split(',') : [];

    if (actorFotos.length === 0) {
      return ['https://cdn-icons-png.flaticon.com/512/149/149071.png'];
    }

    return actorFotos.map((foto: string) => foto
      ? environment.API_URL.replace('/api/', '/static/participantes/') + foto.trim()
      : 'https://cdn-icons-png.flaticon.com/512/149/149071.png');
  }
  
  updateNotificacoesCount() {
    const quizesNaoVisualizados = JSON.parse(localStorage.getItem('quizes_nao_visualizados')) || [];
  
    // Contar quizzes não visualizados
    const totalQuizesNaoVisualizados = quizesNaoVisualizados.filter(quiz => !quiz.visualizado).length;
  
    // Contar notificações não lidas diretamente de this.notificacoes
    const totalNotificacoesNaoLidas = this.notificacoes
      ? this.notificacoes.filter((notificacao: any) => notificacao.is_read === 0 || notificacao.is_read === false).length
      : 0;
  
    this.contadorNotificacoes = totalQuizesNaoVisualizados + totalNotificacoesNaoLidas;
  }
  
  
  redirectNotification(notificacao: any): void {
    if (notificacao.post_id) {
      this.router.navigate(['/home'], { queryParams: { post_id: notificacao.post_id } });
      this.modalNotificacoes = false;
    } else if (notificacao.link) {
      if (notificacao.link.startsWith('/')) {
        this.router.navigate([notificacao.link]);
      } else {
        window.open(notificacao.link, '_blank');
      }
    } else {
      console.error('Nenhuma notificação encontrada na resposta da API.');
    }
  }


}
