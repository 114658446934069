import { Component, Injectable, Output, EventEmitter, OnInit, HostListener, ViewChild, TemplateRef, Renderer2, ElementRef, ChangeDetectorRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Login } from 'src/app/classes/login';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { Location } from '@angular/common';
import * as $ from 'jquery';
import { Platform } from '@ionic/angular';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
    selector: 'app-login-component',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})


export class LoginComponent implements OnInit {

    //nova pagian de login

    @ViewChild('modalAcao', { static: false }) private modalAcao: TemplateRef<any>;
    
    modalLogin = false;

    showModal = false;
    showModalEdit = false;
    showModalFilter = false;
    posts: any = [];
    post_edit: any = [];
  
    foto_src = "https://cdn-icons-png.flaticon.com/512/149/149071.png";
  
    user_id;
    user_name;
    token;
    aniversario = false;
    perfil = parseInt(localStorage.getItem('perfil'));
  
    arquivoFotoSelecionado: File | null = null;
    arquivoVideoSelecionado: File | null = null;
    descricaoPintura = '';
  
    is_loading = false;
    loading_top = false;
    loading_bottom = false;
    loading_global = false;
  
    filtro_texto = '';
    id_grupo = null;
    grupo = [];
    isScrolling = false;
    is_desktop = false;
  
    regulamento = false;
  
    mostrar_pwa = false;
    deferredPrompt: any;
    travado = false;
  
    modal_ativo;
    modal_banner_url;
    pwa_interval;
    
    public modal_ganhou: boolean = false;
    dynamicImageUrl: string;
    
  

    //fim nova pagina

    @ViewChild('modalInstalarIos', { static: false }) private modalInstalarIos: TemplateRef<any>;

    public eye = faEye;
    public mostrarSenha = false;

    public modalReference: any;
    public user: Login;
    public loggedIn: boolean;
    public mode = 'login';
    public msg = '';
    carregando = false;
    public cpf_r = '';

    public senha_atual = '';
    public nova_senha = '';
    public nova_senha_c = '';


    
  sliderOptions = {
    slidesPerView: 1,
    spaceBetween: 10,
    dots: true,
    autoplay: {
      delay: 6000,
      disableOnInteraction: false,
    },
    pagination: {   // Configurações da paginação (dots)
      el: '.swiper-pagination',   // Elemento HTML que irá conter os dots
    },
  };

    grupos = [];


    fixedBanner: string = 'https://admin.demaodupla.com.br/static/banners/24-05-09-03-31banner0.png';


    email = '<small> Favor Preencha os campos abaixo </small> <br> <br> Nome: <br><br> Telefone:<br><br>CNPJ:<br><br> Mensagem:  <br>';

    constructor(private renderer: Renderer2, private http: HttpClient,
        private route: ActivatedRoute, private router: Router, private auth: AuthService, 
        private loader: NgxUiLoaderService, private modalService: NgbModal, private location: Location,  private elementRef: ElementRef, private platform: Platform,
        private cdRef: ChangeDetectorRef, private sanitizer: DomSanitizer) {
        this.user = new Login();
    }

    showModalLogin() {
        this.modalLogin = true;
    }

    ngOnInit() {

        if (localStorage.getItem('documento')) {
            this.user.documento = localStorage.getItem('documento');
        }

        const cpf = this.route.snapshot.queryParamMap.get('cpf');
        this.user.documento = cpf;

        console.log(localStorage.getItem('token'));

        if(localStorage.getItem('token')){
            this.router.navigate(['/home']);
        }

        this.loader.stop();

        //obter a timeline

        this.obter_timeline(0, () => {
            this.set_autoplay(); 
          });

          setTimeout(() => {
            this.showModalLogin();
        }, 8000);
    }

 

    toggle_pass(){
        this.mostrarSenha = !this.mostrarSenha;
    }

    login() {

      if(this.carregando){
        return;
      }

      this.carregando = true;

        if (!this.user.documento) {
            this.msg = 'Preencha o campo CPF';
            this.carregando = false;

            return;
        }

        /* if(this.user.documento == '00000000000' && this.user.senha == '123'){
            this.router.navigate(['/primeiro-acesso']);
            return;
        } */

        localStorage.removeItem('legado');
        localStorage.removeItem('aniversariante');

        this.loader.start();

        this.auth.login(this.user).subscribe(
            (data: any) => {
                console.log(data);

                if(data.legado){
                  localStorage.setItem('cpf_legado', data.legado.cpf);
                  alert('O regulamento do programa foi atualizado, atualize seus dados para continuar');
                  this.router.navigate(['/cadastro', { legado: 'legado' }]);
                  this.carregando = false;
                  return;
                }

                if (data.nome) {
                    this.carregando = false;

                    localStorage.setItem('id_confirmacao', data.id);
                    /* if (data.confirmou_cadastro == 'N') {
                        this.router.navigate(['/confirmacao']);
                        return;
                    } */

                    var nome_view;
                    let nome = data.nome.split(' ');
                    if (nome.length > 2) {
                        nome_view = nome[0] + ' ' + nome[1];
                        if(nome[1].length <= 3 && nome[2] != undefined){
                            nome_view = nome_view + " " + nome[2];
                        }
                    }
                    else {
                        nome_view = data.nome;
                    }

                    localStorage.setItem('userName', nome_view);
                    localStorage.setItem('userId', data.id);
                    localStorage.setItem('token', data.token);
                    localStorage.setItem('perfil', data.perfil);
                    localStorage.setItem('token', data.token);
                    localStorage.setItem('isEmbaixador', data.embaixador);

                    if(data.aniversariante){
                        localStorage.setItem('aniversariante', 'true');
                    }

                    if(data.admin === '1'){
                        localStorage.setItem('userName0', nome_view);
                        localStorage.setItem('userId0', data.id);
                        localStorage.setItem('token0', data.token);
                        localStorage.setItem('perfil0', data.perfil);
                        localStorage.setItem('foto_src0', data.foto);
                        localStorage.setItem('admin', '1');
                    }
                    if (data.foto) {
                        let foto_src = environment.API_URL.replace('/api/', '/static/participantes/' + data.foto);
                        localStorage.setItem('foto_src', foto_src);
                            if(data.admin === '1'){
                                localStorage.setItem('foto_src0', foto_src);
                            }
                    }
                    


                    else{
                        localStorage.removeItem('foto_src');
                    }

                    //localStorage.setItem('ultimo_acesso', data.ultimo_acesso);

                    if(parseInt(data.perfil, 10) > 1){
                        this.router.navigate(['/promotores']).then( () => { location.reload(); });
                    }

                    else{

                        const width = window.innerWidth;

                        if (width > 780) {

                            if(data.primeiro_acesso == 'S'){
                                this.router.navigate(['/primeiro-acesso-desktop']).then( () => { location.reload(); });
                            } 
                            else{
                                this.router.navigate(['/home-desktop']).then(() => { location.reload(); }); 
                            }

                        }
                        else if (width < 780){

                            if(data.primeiro_acesso === 'S'){
                                this.router.navigate(['/primeiro-acesso']).then( () => { location.reload();  });
                            }
                            else{
                                this.router.navigate(['/home']).then(() => { location.reload();  });
                            }

                        }

                    }

                }
                else if (data.texto) {
                    this.msg = data.texto;
                    this.loader.stop();
                    this.carregando = false;
                }
            }
        )
    }

    /* mudar_senha() {
        let user_id = parseInt(localStorage.getItem('userId'));
        this.auth.alterar_senha(user_id, this.senha_atual, this.nova_senha, this.nova_senha_c).subscribe(data => {
            this.msg = data.mensagem;
        })
    }
    */

esqueci_senha_sms() {
    this.loader.start();
    $('#btn-sms').attr('disabled', true);  // Desabilita apenas o botão de SMS
    this.auth.recuperar_senha(this.cpf_r).subscribe(data => {
        $('#btn-sms').attr('disabled', false);  // Reabilita o botão de SMS após a resposta
        this.msg = data.mensagem;
        this.loader.stop();
    });
}

esqueci_senha_email() {
  this.loader.start();
  $('#btn-email').attr('disabled', true);  // Desabilita apenas o botão de E-MAIL
  this.auth.recuperar_senha_email(this.cpf_r).subscribe(data => {
      $('#btn-email').attr('disabled', false);  // Reabilita o botão de E-MAIL após a resposta
      this.msg = data.mensagem;
      this.loader.stop();
  }, error => {
      $('#btn-email').attr('disabled', false);  // Reabilita o botão de E-MAIL mesmo em caso de erro
      this.msg = "Erro ao tentar enviar o e-mail. Por favor, tente novamente.";
      this.loader.stop();
  });
}

suporte_whatsapp() {
  const cpf = this.cpf_r;
  const whatsappNumber = '5581991634909';
  const message = `Gostaria de recuperar a senha de login no Iclub do CPF ${cpf}`;
  const url = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(message)}`;


  // se CPF tiver menos de 11 digitos, retorna uma mensagem de erro
  if (cpf.length < 11) {
    alert('CPF inválido');
    return;
  }

  window.open(url, '_blank');
}


    toggle_senha() {
        const tipo = $('#senha').attr('type');

        switch (tipo) {
            case 'password':
                $('#senha').attr('type', 'text');
                break;
            case 'text':
                $('#senha').attr('type', 'password');
                break;
        }
    }

    abrir_regulamento() {
        this.regulamento = true;
    }



    //Nova pagina de login

      
      set_scroll() {
    
        const options = {
          root: null, // O elemento root será o viewport
          rootMargin: '0px', // Margem ao redor do elemento root
          threshold: 1 // Porcentagem de visibilidade necessária para disparar o evento
        };
    
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              // O elemento está visível na viewport, chame a função obter_timeline()
              const offset = this.posts.length;
              if (offset > 0) {
                this.obter_timeline(offset, () => { this.set_autoplay(); });
              }
            }
          });
        }, options);
    
        const viewportElement = this.elementRef.nativeElement.querySelector('#fim_timeline');
        observer.observe(viewportElement);
    
      }
    
      set_autoplay() {
        const options = {
          root: null,
          rootMargin: '0px',
          threshold: 0.5 // Altere este valor conforme necessário (de 0 a 1)
        };
    
        const callback = (entries, observer) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              const videoElement = entry.target.querySelector('video');
              if (videoElement) {
                console.log(videoElement);
                videoElement.play(); // Inicia a reprodução do vídeo
              }
            } else {
              const videoElement = entry.target.querySelector('video');
              if (videoElement) {
                console.log(videoElement);
                videoElement.pause(); // Pausa a reprodução do vídeo
              }
            }
          });
        };
    
        this.posts.forEach(post => {
          const postElement = document.getElementById('post-' + post.id);
          const observer = new IntersectionObserver(callback, options);
          observer.observe(postElement);
        });
      }
    
      private registrarAcesso() {
        this.http.get<any>(environment.API_URL.concat('acoes_iclub/registrar_acesso/'+this.user_id));
      }
    
      obter_pwa() {
        const isInStandaloneMode = () =>
          window.matchMedia('(display-mode: standalone)').matches || (window.navigator as any).standalone;
      
        const isIOS = this.platform.is('ios');
      
        console.log(isInStandaloneMode(), isIOS);
        if (!isInStandaloneMode() && !isIOS && this.deferredPrompt) {
          this.mostrar_pwa = true;
          clearInterval(this.pwa_interval);
        }
      }
    
      responder_pwa(resposta) {
        switch (resposta) {
          case 'nao':
            localStorage.setItem('resposta_pwa', 'nao');
            this.mostrar_pwa = false;
            break;
          case 'sim':
            localStorage.setItem('resposta_pwa', 'sim');
            this.mostrar_pwa = false;
            this.add_pwa();
            break;
        }
      }
    
      add_pwa() {
        if (this.deferredPrompt) {
          this.deferredPrompt.prompt();
          this.deferredPrompt.userChoice
            .then((choiceResult: any) => {
              if (choiceResult.outcome === 'accepted') {
                console.log('O aplicativo foi instalado');
              } else {
                console.log('O usuário cancelou a instalação');
              }
              this.deferredPrompt = null;
            });
        } else {
          console.error('O prompt de instalação não está disponível.');
          // Possivelmente reativar a função com uma mensagem de erro
        }
      }
    
      abrir_modal_acao() {
        if (!this.modalService.hasOpenModals()) {
          this.modalService.open(this.modalAcao, { windowClass: 'modal-acao', size: 'md' });
        }
      }
    
      obter_modal() {
        this.http.post<any>(environment.API_URL.concat('acoes/modal_iclub'), {
          id_usuario: this.user_id
        }).subscribe((data: any) => {
          if (data.status == 1) {
    
            if (localStorage.getItem('viumodal_' + data.modal.titulo) === '01') {
              //return;
            }
            console.log(data.modal);
            this.modal_ativo = data.modal;
            this.modal_banner_url = this.sanitizer.bypassSecurityTrustResourceUrl(this.modal_ativo.banner);
            setTimeout(() => {
              this.abrir_modal_acao();
            }, 2000);
    
            localStorage.setItem('viumodal_' + data.modal.titulo, '01');
    
          }
        });
      }
    
      private obter_timeline(offset: number, callback: () => void) {
        const formData = new FormData();
      
        // Adicione os campos ao FormData conforme necessário
        if (this.token) {
          formData.append('token', this.token);
        }
        if (this.user_id) {
          formData.append('id_participante', this.user_id);
        }
        if (this.id_grupo) {
          formData.append('id_grupo', this.id_grupo);
        }
      
        formData.append('limit', '10'); 
      
        let url = 'sociais_iclub/obter_timeline_publica/' + offset;
        if (this.filtro_texto) {
          url = url.concat('?filtro=' + this.filtro_texto);
        }
      
        this.http.post<any>(environment.API_URL.concat(url), formData).subscribe(
          (data: any) => {
            if (data.status === 1) {
              if (offset > 0) {
                this.posts = this.posts.concat(data.posts);
              } else {
                this.posts = data.posts;
              }
      
              // Verifique se menos de 10 posts foram carregados, indicando o fim da lista
              if (data.posts.length < 10) {
                // Mostrar modal de login aqui
                this.showLoginModal();
              }
      
              this.cdRef.detectChanges();
            } else {
              console.error('Erro na resposta da API:', data.error || 'Erro desconhecido');
            }
      
            this.loading_bottom = false;
            this.loading_top = false;
      
            callback();
          },
          error => {
            console.error('Erro ao buscar posts:', error);
            this.loading_top = false;
            this.loading_bottom = false;
      
            callback();
          }
        );
      }

      private showLoginModal() {
        // Implemente a lógica para exibir o modal de login aqui
        console.log("Mostrar modal de login");
        this.showModal = true; // Supondo que você tenha uma variável de controle para o modal
        }
      
    
      filtrar() {
        this.showModalFilter = false;
        this.posts = [];
    
        if (this.travado) {
          alert('O regulamento do programa foi atualizado, por favor atualize seus dados para continuar.');
          this.router.navigate(['/cadastro', { legado: 'legado' }]);
        }
    
        this.obter_timeline(0, () => { this.set_autoplay(); });
      }
    
      limpar_filtros() {
        this.showModalFilter = false;
        this.filtro_texto = '';
        this.posts = [];
    
        this.obter_timeline(0, () => { this.set_autoplay(); });
    
      }
    
      onFileSelected(event: any, tipo: string) {
        const arquivoSelecionado = event.target.files[0];
        this.arquivoFotoSelecionado = null;
        this.arquivoVideoSelecionado = null;
        if (tipo === 'foto') {
          this.arquivoFotoSelecionado = arquivoSelecionado;
        } else if (tipo === 'video') {
          this.arquivoVideoSelecionado = arquivoSelecionado;
        }
      }
    
      async postar() {
    
        if (this.checar_trava()) { return }
    
        const formData = new FormData();
        this.filtro_texto = '';
    
        console.log(this.arquivoFotoSelecionado);
        if (this.arquivoFotoSelecionado) {
          formData.append('imagem', this.arquivoFotoSelecionado);
        }
    
        if (this.arquivoVideoSelecionado) {
          formData.append('video', this.arquivoVideoSelecionado);
        }
    
        if (!this.arquivoFotoSelecionado && !this.arquivoVideoSelecionado && !this.descricaoPintura) {
          alert("Selecione Foto, Vídeo ou escreva algo interessante...");
          return;
        }
    
        const palavrasEncontradas = await this.contemPalavrasCensuradas(this.descricaoPintura);
        if (palavrasEncontradas.length > 0) {
          alert(`O texto informado contém palavras censuradas: ${palavrasEncontradas.join(', ')}`);
          return;
        }
    
        formData.append('text', this.descricaoPintura);
        formData.append('id_participante', this.user_id);
        formData.append('token', localStorage.getItem('token'));
    
    
        if (this.id_grupo) {
          formData.append('id_grupo', this.id_grupo);
        }
    
        this.toggleModal();
        this.loading_top = true;
    
        this.http.post<any>(environment.API_URL.concat('sociais_iclub/postar'), formData)
          .subscribe(response => {
            this.loading_top = false;
    
            if(response.pontos){
    
              this.pontos_ganhos = response.pontos;
              this.animacao_saldo();
              // this.dynamicImageUrl = '/assets/win_post.png'
              // let efeito_sonoro = new Audio("assets/audio/positivo.wav");
              // efeito_sonoro.play(); 
            }
    
            if (response.status == 0) {
              alert("Erro: " + response.error);
            }
            if (response.status == 1) {
              this.obter_timeline(0, () => { this.set_autoplay(); });
            }
            // Faça qualquer ação adicional necessária
            this.descricaoPintura = '';
          }, error => {
            console.error('Erro ao enviar os dados:', error);
            console.log(error);
          });
      }
    
      async contemPalavrasCensuradas(descricao: string): Promise<string[]> {
        let palavrasCensuradasEncontradas: string[] = [];
        try {
          const data: any[] = await this.http.get<any[]>(environment.API_URL.concat('censuras_iclub/obter_censuras')).toPromise();
      
          data.forEach(item => {
            if (item && item.termos) {
              try {
                var termosArray = JSON.parse(item.termos);
                if (Array.isArray(termosArray)) {
                  termosArray.forEach((palavra: string) => {
                    if (descricao.toLocaleLowerCase().includes(palavra.toLocaleLowerCase())) {
                      palavrasCensuradasEncontradas.push(palavra);
                    }
                  });
                } else {
                  console.log("O campo 'termos' não é um array válido.");
                }
              } catch (error) {
                console.log("Termos como string:", item.termos);
              }
            } else {
              console.log("A propriedade 'termos' não está presente nos dados recebidos.");
            }
          });
      
          return palavrasCensuradasEncontradas;
        } catch (error) {
          console.error('Erro ao obter censuras:', error);
          return palavrasCensuradasEncontradas; // Ou trate o erro de acordo com sua necessidade
        }
      }
    
      editar_post() {
        const formData = new FormData();
        this.filtro_texto = '';
    
        console.log(this.arquivoFotoSelecionado);
        if (this.arquivoFotoSelecionado) {
          formData.append('imagem', this.arquivoFotoSelecionado);
        }
    
        if (this.arquivoVideoSelecionado) {
          formData.append('video', this.arquivoVideoSelecionado);
        }
    
        if (!this.arquivoFotoSelecionado && !this.arquivoVideoSelecionado && !this.post_edit.text) {
          alert('Selecione Foto, Vídeo ou escreva algo interessante...');
          return;
        }
    
        formData.append('text', this.post_edit.text);
        formData.append('id_participante', this.user_id);
        formData.append('token', localStorage.getItem('token'));
        formData.append('id_post', this.post_edit.id);
    
        this.loading_top = true;
        this.toggleModalEdit();
    
        this.http.post<any>(environment.API_URL.concat('sociais_iclub/postar'), formData)
          .subscribe(response => {
            this.loading_top = false;
    
            if (response.status === 0) {
              alert('Erro: ' + response.error);
            }
            if (response.status === 7) {
              alert('Sua conexão expirou, faça login novamente para continuar');
              localStorage.removeItem('token');
              localStorage.removeItem('userId');
              this.router.navigate(['/login']);
            }
            if (response.status === 1) {
    
              const index = this.posts.findIndex(p => p.id === this.post_edit.id); // Encontra o índice do item com o ID desejado
              if (index !== -1) {
                this.posts[index] = response.post; // Substitui o item pelo novo objeto
                this.posts[index].showOptions = false;
              } else {
                console.log('Item não encontrado'); // Caso o item não seja encontrado
              }
            }
            // Faça qualquer ação adicional necessária
            this.post_edit = [];
          }, error => {
            console.error('Erro ao enviar os dados:', error);
            console.log(error);
            // Trate o erro de acordo com sua necessidade
          });
      }
    
      async comentar($event: any) {
    
        if (this.checar_trava()) { return }
    
        let event = $event.$event;
        let content = $event.content;
        let tipo = $event.type;
    
        const button = event.target;
        const textarea = button.parentNode.querySelector('.comment-input') as HTMLTextAreaElement;
        const comentario = textarea.value;
    
        if (!comentario || comentario.trim() == '') {
          alert("Escreva algo legal"); return;
        }
    
        const palavrasEncontradas = await this.contemPalavrasCensuradas(comentario);
        if (palavrasEncontradas.length > 0) {
          alert(`O texto informado contém palavras censuradas: ${palavrasEncontradas.join(', ')}`);
          return;
        }
    
        const formData = new FormData();
    
        formData.append('text', comentario);
        formData.append('id_participante', this.user_id);
        formData.append('token', localStorage.getItem('token'));
    
        if (tipo == 'post') {
          formData.append('id_post', content.id);
        }
        if (tipo == 'comment') {
          formData.append('id_comment', content.id);
          formData.append('id_post', content.id_post);
        }
    
        console.log('Enviando dados:', {
          text: comentario,
          id_participante: this.user_id,
          token: localStorage.getItem('token'),
          id_post: content.id,
          id_comment: tipo == 'comment' ? content.id : null
        });
    
        this.loading_global = true;
    
        this.http.post<any>(environment.API_URL.concat('sociais_iclub/comentar'), formData, { responseType: 'text' as 'json' })
        .subscribe(response => {
          try {
            const jsonResponse = JSON.parse(response);
            if (jsonResponse.status == 1) {
              textarea.value = '';
              this.loading_global = false;
              let index;
              if (tipo == 'post') {
                index = this.posts.findIndex(p => p.id === content.id);
              } else {
                index = this.posts.findIndex(p => p.id === content.id_post);
              }
      
              if (jsonResponse.pontos) {
                this.pontos_ganhos = jsonResponse.pontos;
                this.animacao_saldo();
              }
      
              if (index !== -1) {
                this.posts[index] = jsonResponse.post;
                this.posts[index]['showPostComments'] = true;
              } else {
                console.log('Item não encontrado');
              }
            }
          } catch (e) {
            console.error('Erro ao analisar a resposta do servidor:', e);
            console.log('Resposta do servidor:', response);
          }
        }, error => {
          console.error('Erro ao enviar os dados:', error);
        });
      
      }
    
      reactPost(post: any, reaction: string): void {
        if (this.checar_trava()) { return; }
    
        if (!post.selectedReaction) { //Se não tiver nenhuma reação selecionada, adiciona +1 na reação selecionada
          post.selectedReaction = reaction;
          post.reactions[reaction] += 1;
    
          this.create_update_reaction('post', reaction, post.id);
        }
        else if (post.selectedReaction !== reaction) {
          post.reactions[post.selectedReaction] -= 1;
          post.reactions[reaction] += 1;
          post.selectedReaction = reaction;
    
          this.create_update_reaction('post', reaction, post.id);
        }
        else { // Se a reação selecionada for igual a reação que já estava selecionada, remove a reação selecionada
          post.reactions[post.selectedReaction] -= 1;
          post.selectedReaction = null;
    
          this.remove_reaction('post', post.id);
        }
        let efeito_sonoro = new Audio("assets/audio/react.wav");
        efeito_sonoro.play(); 
      }
    
      reactComment($event): void {
        if (this.checar_trava()) { return }
    
        const comment = $event.comment;
        const reaction = $event.type;
    
        if (!comment.selectedReaction) { //Se não tiver nenhuma reação selecionada, adiciona +1 na reação selecionada
          this.create_update_reaction('comment', reaction, comment.id_post, comment.id);
    
          comment.selectedReaction = reaction;
          comment.reactions[reaction] += 1;
    
        }
        else if (comment.selectedReaction !== reaction) {
          this.create_update_reaction('comment', reaction, comment.id_post, comment.id);
    
          comment.reactions[comment.selectedReaction] -= 1;
          comment.reactions[reaction] += 1;
          comment.selectedReaction = reaction;
    
        }
        else { // Se a reação selecionada for igual a reação que já estava selecionada, remove a reação selecionada
          comment.reactions[comment.selectedReaction] -= 1;
          comment.selectedReaction = null;
    
          this.remove_reaction('comment', comment.id);
        }
        let efeito_sonoro = new Audio("assets/audio/react.wav");
        efeito_sonoro.play(); 
      }
    
      create_update_reaction(type, reaction, id_post, id_comment = null) {
        if (this.checar_trava()) { return }
    
        const formData = new FormData();
    
        formData.append('type', reaction);
        formData.append('token', localStorage.getItem('token'));
        formData.append('id_participante', this.user_id);
        formData.append('id_post', id_post);
    
        if (type == 'comment')
          formData.append('id_comment', id_comment);
    
    
        this.http.post<any>(environment.API_URL.concat('sociais_iclub/reagir'), formData)
          .subscribe(data => {
            if (data.status == 0) {
              alert("Erro: " + data.error);
            }
            if (data.status == 7) {
              alert("Sua conexão expirou, faça login novamente para continuar");
              localStorage.removeItem('token');
              localStorage.removeItem('userId');
              this.router.navigate(['/login']);
            }
            if (data.status == 1) {
              console.log(id_post);
              const index = this.posts.findIndex(p => p.id === id_post); // Encontra o índice do item com o ID desejado
    
              if (index !== -1) {
                this.posts[index]['reactions'] = data.post.reactions; // Substitui o item pelo novo objeto
                this.posts[index]['showPostComments'] = true;
    
                if (type == 'comment') {
                  const c = this.posts[index]['comments'].findIndex(c => c.id === id_comment);
                  console.log(c);
                  this.posts[index]['comments'] = data.post.comments;
                  this.posts[index]['comments'][c]['showReactions'] = true;
                  console.log(this.posts[index]);
                }
              } else {
                console.log('Item não encontrado'); // Caso o item não seja encontrado
              }
    
            }
          });
      }
    
      remove_reaction(type, id) {
        const formData = new FormData();
    
        formData.append('token', localStorage.getItem('token'));
        formData.append('id_participante', this.user_id);
    
        switch (type) {
          case "post":
            formData.append('id_post', id);
            break;
          case "comment":
            formData.append('id_comment', id);
            break;
        }
    
        this.http.post<any>(environment.API_URL.concat('sociais_iclub/remover_reacao'), formData)
          .subscribe(data => {
            if (data.status == 0) {
              alert("Erro: " + data.error);
            }
            if (data.status == 7) {
              alert("Sua conexão expirou, faça login novamente para continuar");
              localStorage.removeItem('token');
              localStorage.removeItem('userId');
              this.router.navigate(['/login']);
            }
            if (data.status == 1) {
              console.log('sucesso');
            }
          });
      }
    
      denunciar(type, content) {
        if (this.checar_trava()) { return }
    
        const formData = new FormData();
    
        formData.append('token', localStorage.getItem('token'));
        formData.append('id_participante', this.user_id);
        content.showOptions = false;
    
        if (type == 'comment') {
          formData.append('id_comment', content.id);
          formData.append('id_post', content.id_post);
        }
        else {
          formData.append('id_post', content.id);
        }
    
        this.loading_global = true;
    
        this.http.post<any>(environment.API_URL.concat('sociais_iclub/denunciar'), formData)
          .subscribe(data => {
            if (data.status == 0) {
              alert("Erro: " + data.error);
            }
            if (data.status == 7) {
              alert("Sua conexão expirou, faça login novamente para continuar");
              localStorage.removeItem('token');
              localStorage.removeItem('userId');
              this.router.navigate(['/login']);
            }
            if (data.status == 1) {
              alert("Denuncia realizada, iremos avaliar sua denuncia");
            }
            this.loading_global = false;
          });
      }
    
      deletar_post(content) {
    
        content.showOptions = false;
    
        const formData = new FormData();
        formData.append('token', localStorage.getItem('token'));
        formData.append('id_participante', this.user_id);
        formData.append('id_post', content.id);
    
        this.loading_global = true;
    
        this.http.post<any>(environment.API_URL.concat('sociais_iclub/deletar'), formData)
          .subscribe(data => {
            if (data.status == 0) {
              alert("Erro: " + data.error);
            }
            if (data.status == 7) {
              alert("Sua conexão expirou, faça login novamente para continuar");
              localStorage.removeItem('token');
              localStorage.removeItem('userId');
              this.router.navigate(['/login']);
            }
            if (data.status == 1) {
              alert("Post apagado com sucesso");
              this.obter_timeline(0, () => { });
            }
            this.loading_global = false;
          });
      }
    
      deletar_comment($event) {
    
        const content = $event.content;
        content.showOptions = false;
    
        const formData = new FormData();
    
        formData.append('token', localStorage.getItem('token'));
        formData.append('id_participante', this.user_id);
        formData.append('id_comment', content.id);
        formData.append('id_post', content.id_post);
    
        this.loading_global = true;
    
        this.http.post<any>(environment.API_URL.concat('sociais_iclub/deletar'), formData)
          .subscribe(data => {
            if (data.status == 0) {
              alert("Erro: " + data.error);
            }
            if (data.status == 7) {
              alert("Sua conexão expirou, faça login novamente para continuar");
              localStorage.removeItem('token');
              localStorage.removeItem('userId');
              this.router.navigate(['/login']);
            }
            if (data.status == 1) {
    
              let index = this.posts.findIndex(p => p.id === content.id_post); // Encontra o índice do item com o ID desejado
    
              if (index !== -1) {
                this.posts[index] = data.post; // Substitui o item pelo novo objeto
                this.posts[index]['showPostComments'] = true;
              } else {
                console.log('Item não encontrado'); // Caso o item não seja encontrado
              }
            }
    
            this.loading_global = false;
          });
      }
    
      toggleReactions(post: any): void {
        post.showReactions = !post.showReactions;
      }
    
      toggleComments(post: any): void {
        post.showComments = !post.showComments;
      }
    
      togglePostComments(post: any): void {
        post.showPostComments = !post.showPostComments;
      }
    
      toggleCommentReactions(comment: any): void {
        comment.showReactions = !comment.showReactions;
      }
    
      toggleDenunciation(content: any): void {
        content.showOptions = !content.showOptions;
      }
    
      toggleModal(): void {
        this.arquivoFotoSelecionado = null;
        this.arquivoVideoSelecionado = null;
        this.showModal = !this.showModal;
      }
    
      toggleModalEdit(post: any = []): void {
        this.arquivoFotoSelecionado = null;
        this.arquivoVideoSelecionado = null;
        this.post_edit = post;
        this.showModalEdit = !this.showModalEdit;
    
        if (post.showOptions) {
          post.showOptions = false;
        }
      }
    
      toggleModalFilter(): void {
        this.showModalFilter = !this.showModalFilter;
      }
    
      terminar_cadastro() {
        this.router.navigate(['cadastro']);
      }
    
      checar_trava() {
    
        if (this.travado) {
          alert('O regulamento do programa foi atualizado, por favor atualize seus dados para continuar.');
          const isDesktopRoute = this.router.url.includes('-desktop');
          const currentRoute = this.router.url.replace('-desktop', '');
          const routeDesktop = currentRoute + '-desktop';
    
          if ($(window).width() < 780) {
            this.router.navigate(['/cadastro', { legado: 'legado' }]).then(() => { location.reload(); });
          } else if ($(window).width() > 780) {
            this.router.navigate(['/cadastro-desktop', { legado: 'legado' }]).then(() => { location.reload(); });
          }
          return true;
        }
    
        if (!this.user_id) {
          alert('Faça login para continuar sua açao.');
          this.showModalLogin();
          return true;
        }
    
      }
    
      pontos;
      moedas;
      pontos_ganhos;
      moedas_ganhos;
      loaded_data;
    
      obter_dados() {
        this.http.get<any>(environment.API_URL.concat('pontos_iclub/index?id_participante=' + this.user_id)).subscribe(data => {
          this.pontos = data.pontos;
          this.moedas = data.moedas;
          this.loaded_data = true;
        });
      }
    
      animacao_saldo() {
        this.modal_ganhou = true;
        setTimeout(() => { this.modal_ganhou = false }, 4000);
      }
    
      atualizar_saldos($event) {
        setTimeout(() => {
          this.obter_dados();
          this.modal_ganhou = false;
        }, 3000);
      }

      

}