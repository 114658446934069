import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ChangeDetectorRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-header-user',
  templateUrl: './header-user.component.html',
  styleUrls: ['./header-user.component.scss'],
})
export class HeaderUserComponent implements OnInit {
  user_id: string;
  foto_src: string = "https://cdn-icons-png.flaticon.com/512/149/149071.png";
  user_name: string;
  pontos: number = 0;
  progress = {
    certificados: 0,
    porcentagem: 0
  };

  @Input() public pagina: string;
  @Input() public posicao: string = '';
  @Input() public isEmbaixador: number = 0;

  @Output() userClicked: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private location: Location,
    private http: HttpClient,
    private cdr: ChangeDetectorRef,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.user_id = localStorage.getItem("userId") || '';
    this.user_name = localStorage.getItem('userName') || '';
    this.pontos = parseInt(localStorage.getItem('pontos')) || 0;

    const storedFotoSrc = localStorage.getItem('foto_src');
    if (storedFotoSrc) {
      this.foto_src = storedFotoSrc;
    }

    this.loadProgress();
    this.loadPoints();

    // Atualiza os pontos sempre que a navegação ocorre
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.loadPoints();
      }
    });
  }

  private async loadProgress(): Promise<void> {
    try {
      const formData = new FormData();
      formData.append('token', localStorage.getItem('token') || '');
      formData.append('id_participante', this.user_id);

      const response = await this.http.post<any>(
        `${environment.API_URL}participantes_iclub/obter_progresso/${this.user_id}`, formData
      ).toPromise();

      if (response.status === 1) {
        this.progress = response.progresso;
        this.refreshView();
      }
    } catch (error) {
      console.error('Erro ao obter progresso:', error);
    }
  }

  private async loadPoints(): Promise<void> {
    try {
      const response = await this.http.get<any>(
        `${environment.API_URL}pontos_iclub/index?id_participante=${this.user_id}`
      ).toPromise();
  
      this.pontos = response.pontos || 0;
      localStorage.setItem('pontos', String(this.pontos));  
    } catch (error) {
      console.error('Erro ao obter pontos:', error);
    }
  }

  private refreshView(): void {
    this.cdr.detectChanges();
  }

  goback(): void {
    this.location.back();
  }

  onUserClick(): void {
    this.userClicked.emit();
  }
}
