import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu-inferior',
  templateUrl: './menu-inferior.component.html',
  styleUrls: ['./menu-inferior.component.scss'],
})
export class MenuInferiorComponent implements OnInit {

  @Input() pagina;
  
  constructor(private router: Router

  ) { }

  ngOnInit() {}

  home_reload(){
    this.router.navigate(['/home']).then(()=> window.location.reload() );
  }
}
