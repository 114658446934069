import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-embaixadores',
  templateUrl: './embaixadores.component.html',
  styleUrls: ['./embaixadores.component.scss'],
})
export class EmbaixadoresComponent implements OnInit {


  embaixadores = [];
  user_id;
  token;
  loading_global = false;
  is_desktop = false;


  constructor(private location:Location, private http: HttpClient, private router: Router, private route:ActivatedRoute) { }

  ngOnInit() {
    this.user_id = localStorage.getItem("userId");
    this.token = localStorage.getItem("token");

    if (!this.user_id || !this.token) {
      this.router.navigate(['/login']);
    }

    let rota:string = this.route.snapshot.url.toString();

    if(rota.includes('desktop')){
      this.is_desktop = true;
    }

    this.obterEmbaixadores();
  }


  obterEmbaixadores() {
    const formData = new FormData();
    this.loading_global = true;
  
    formData.append('token', this.token);
    formData.append('id_participante', this.user_id);
  
    this.http.post<any>(environment.API_URL.concat('sociais_iclub/obter_embaixadores'), formData).subscribe(
      data => {
        if (data.status == 1) {
          this.embaixadores = data.embaixadores;
        } else {
          alert("Erro: " + data.error);
        }
  
        this.loading_global = false;
      },
      error => {
        console.error('Erro ao obter embaixadores:', error);
        this.loading_global = false;
      }
    );
  }
  

  seguirUsuario(embaixador) {
    const formData = new FormData();
    formData.append('id_seguidor', this.user_id);
    formData.append('id_seguido', embaixador.id);

    this.http.post<any>(environment.API_URL.concat('sociais_iclub/seguir_usuario'), formData)
      .subscribe(data => {
        if (data.status === 1) {
          embaixador.seguindo = true;
          this.router.navigate(['/home'], { queryParams: { id_participante: embaixador.id } });
        } else {
          alert('Erro ao seguir o usuário: ' + data.error);
        }
      }, error => {
        console.error('Erro na requisição:', error);
      });
  }

  visitarPerfil(embaixador) {
    this.router.navigate(['/home'], { queryParams: { id_participante: embaixador.id } });
  }

  goback(){
    this.location.back();
  }


}
