import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Renderer2, ViewChild, EventEmitter, Output, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ignoreElements } from 'rxjs/operators';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from 'src/app/classes/user';
import * as $ from 'jquery';
import { Endereco } from 'src/app/classes/endereco';
import { AuthService } from 'src/app/services/auth.service';
import { Location } from '@angular/common';
@Component({
  selector: 'app-perfil-usuario',
  templateUrl: './perfil-usuario.component.html',
  styleUrls: ['./perfil-usuario.component.scss'],
})

export class PerfilUsuarioComponent implements OnInit {

  public preferencias = [
    {"value":'esportes' ,"nome":"Esportes", 'id': 1, 'checked':false},
    {"value":'cinema' ,"nome":"Cinema/Teatro", 'id': 2, 'checked':false},
    {"value":'tecnologia' ,"nome":"Tecnologia", 'id': 3, 'checked':false},
    {"value":'decoracao' ,"nome":"Decoração", 'id': 4, 'checked':false},
    {"value":'viagens' ,"nome":"Viagens", 'id': 5, 'checked':false},
    {"value":'leitura' ,"nome":"Leitura/Cursos", 'id': 6, 'checked':false},
    {"value":'fitness' ,"nome":"Fitness", 'id': 7, 'checked':false},
    {"value":'pets' ,"nome":"Pets", 'id': 8, 'checked':false},
    {"value":'gourmet' ,"nome":"Gourmet", 'id': 9, 'checked':false},
    {"value":'games' ,"nome":"Games", 'id': 10, 'checked':false},
    {"value":'moda' ,"nome":"Moda", 'id': 11, 'checked':false},
    {"value":'beleza' ,"nome":"Beleza", 'id': 12, 'checked':false}
  ];

  public preferencias_atuais:any = [{}];
  public preferencias_selecionadas:any = [{}];

  public usuario : User;
  public endereco : Endereco;
  
  user_id;
  token;
  user_name;
  perfil = parseInt(localStorage.getItem('perfil'));

  pontos;

  pontos_ganhos;
  modal_ganhou:boolean = false;

  modal;
  modal_banner_url;
  parceiros;

  alterar_senha:boolean = false;

  senha_atual;
  nova_senha;
  nova_senha_c;
  senha_err_msg;
  senha_scc_msg;

  regulamento = false;
  is_desktop = false;
  @ViewChild('modalAcao', { static: false }) private modalAcao: TemplateRef<any>;
 
  constructor( renderer: Renderer2, private route: ActivatedRoute, private router: Router, private modalService: NgbModal, private sanitizer: DomSanitizer, private http: HttpClient, private loader: NgxUiLoaderService, private auth:AuthService, private location:Location) { 
    this.usuario = new User();
    this.endereco = new Endereco();
  }

  ngOnInit() { 

    this.user_id = localStorage.getItem('userId');
    this.token = localStorage.getItem("token");
    this.user_name = localStorage.getItem('userName');
  
    if (!this.user_id || !this.token) {
      this.router.navigate(['/login']);
    }

    let rota:string = this.route.snapshot.url.toString();

    if(rota.includes('desktop')){
      this.is_desktop = true;
    }

  

    this.obter_dados();
    this.obter_endereco();

  }

  update_preferencias(){
    let p = this.preferencias.filter( function(preferencia){ return preferencia.checked == true });
    this.preferencias_selecionadas = p;
    console.log(this.preferencias_selecionadas);
    console.log(this.preferencias);
  }

  obter_dados(){

    this.http.get<any>(environment.API_URL.concat('pontos_iclub?id_participante='+this.user_id)).subscribe(data => {
      this.pontos = data.pontos;    
    });

    this.http.get<any>(environment.API_URL.concat('participantes_iclub/obter_dados/'+this.user_id)).subscribe(data => {
      this.usuario = data.usuario;
    });

  }

  obter_endereco(){
    this.http.get(environment.API_URL.concat('participantes_iclub/obter_endereco/'+this.user_id)).subscribe( (data:any) => {
        if(data.status == 1){
            console.log(data);
            this.endereco = data.endereco;
        }
        else{
            console.log(data);
        }
    })
  }

  viacep() {

    if (this.endereco.cep.length < 8) {
        return false;
    }

    this.endereco.endereco = 'aguarde...';
    this.http.get<any>('https://viacep.com.br/ws/'+this.endereco.cep+"/json").subscribe((end) => {

        this.endereco.endereco = end.logradouro;
        this.endereco.complemento = end.complemento;
        this.endereco.bairro = end.bairro;
        this.endereco.cidade = end.localidade;
        this.endereco.estado = end.uf;
        console.log(this.endereco);
    });

  }

  submit_form(){

    this.http.post<any>(environment.API_URL.concat('participantes_iclub/update'), 
    {"id": this.user_id, "user":  this.usuario , "endereco": this.endereco }).subscribe( data => {
        
        this.loader.stop();

        if(data['status'] == 1){
            alert('dados atualizados com sucesso');
            
            var nome_view;
            let nome = this.usuario.nome.split(' ');
            if(nome.length > 2){
              nome_view = nome[0]+' '+nome[1]; 
            }
            else{
              nome_view = this.usuario.nome; 
            }

            localStorage.setItem("userName", nome_view);

            window.location.reload();
        }
        else
            alert('houve um problema ao atualizar seus dados, tente novamente dentro de alguns minutos.')
            
    });
  }

  atualizar_senha(){
    this.senha_err_msg = '';
    
    if(!this.senha_atual || this.nova_senha.length <= 5){
        this.senha_err_msg = "Preencha os campos corretamente, sua nova senha deve ter no minimo 6 caracteres.";
        return;
    }

    if(this.nova_senha != this.nova_senha_c){
        this.senha_err_msg = "As senhas não conferem.";
        return;
    }

    // Verifica se a senha tem pelo menos 6 dígitos
    if (this.nova_senha.length < 6) {
        this.senha_err_msg = "Sua senha deve ter ao menos 6 caracteres";
        return false;
    }

    else if(this.nova_senha != this.nova_senha_c){
        this.senha_err_msg = "As senhas não conferem";
        return false; 
    }

    // Verifica se a senha contém pelo menos um caractere especial
    var caracteresEspeciais = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    if (!caracteresEspeciais.test(this.nova_senha)) {
        this.senha_err_msg = "A senha deve ter ao menos 1(um) caractere especial";
        return false;
    }

    // Verifica se a senha contém pelo menos uma letra maiúscula
    var letraMaiuscula = /[A-Z]/;
    if (!letraMaiuscula.test(this.nova_senha)) {
        this.senha_err_msg = "A senha deve ter ao menos 1(uma) letra maiúscula";
        return false;
    }

    this.auth.alterar_senha(parseInt(this.user_id), this.senha_atual, this.nova_senha, this.nova_senha_c).subscribe( data => {
        this.senha_scc_msg = data.mensagem;
    });
  }

  abrir_modal_acao() {
    this.modalService.open(this.modalAcao, { windowClass: 'modal-acao', size: 'md' });
  }

  change_img(){
    $(".imgInp").click();
  }

  deletar(){

    if(!window.confirm("Ao deletar sua conta você perderá todos os seus dados. Tem certeza que deseja continuar?")){
      return false;
    }

    this.http.post<any>(environment.API_URL.concat("participantes_iclub/descadastrar"),
    {id_usuario: this.user_id}).subscribe( data => {
      if(data.status == 1){
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
        this.router.navigate(['/login']);
        location.reload();
      }
    })

  }
}

