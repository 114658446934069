import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-notification-permission',
  templateUrl: './notification-permission.component.html',
  styleUrls: ['./notification-permission.component.scss'],
})
export class NotificationPermissionComponent implements OnInit {

  notificationDenied: boolean = false;
  showPermissionBox: boolean = false;

  constructor() {}

  ngOnInit(): void {
    if (Notification.permission === 'default' || Notification.permission === 'denied') {
      this.notificationDenied = true;
    }
  }

  togglePermissionBox() {
    this.showPermissionBox = !this.showPermissionBox;
  }

  requestPermission() {
    Notification.requestPermission().then(permission => {
      if (permission === 'granted') {
        this.notificationDenied = false;
        this.showPermissionBox = false;
        // Notificação aceita - você pode enviar uma notificação aqui
      }
    });
  }
}