import { Component, Input, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss'],
})
export class SplashScreenComponent implements OnInit, OnDestroy {

  @Input() public loading = true;

  public loadingText = "Finalizando os detalhes de cor e brilho...";
  public messageIndex = 0; // Índice inicial das mensagens
  public loadingMessages = [
    "Preparando as cores perfeitas...",
    "Misturando os tons para você...",
    "Carregando inspiração para sua pintura...",
    "Ajustando a consistência das tintas...",
    "Criando a base ideal para sua arte...",
    "Selecionando as melhores texturas...",
    "Finalizando os detalhes de cor e brilho..."
  ];

  public loadingWarning = false;
  private intervalId: any; // Armazena o ID do intervalo para limpeza posterior

  constructor() { }

  // Função para atualizar o texto de carregamento a cada 3.5 segundos
  updateLoadingText = () => {
    this.loadingText = this.loadingMessages[this.messageIndex];
    this.messageIndex = (this.messageIndex + 1) % this.loadingMessages.length; // Passa para a próxima mensagem em loop
  }

  ngOnInit() {
    // Configura o intervalo para atualizar o texto de carregamento
    this.intervalId = setInterval(this.updateLoadingText, 2500);

    // Exibe o aviso de carregamento após 15 segundos
    setTimeout(() => { 
      this.loadingWarning = true;
    }, 40000);
  }

  ngOnDestroy() {
    // Limpa o intervalo ao destruir o componente para evitar vazamentos de memória
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

}
