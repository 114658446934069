import { HttpClient } from '@angular/common/http';
import {
  Component, OnInit, ChangeDetectorRef, ElementRef,
  HostListener, Renderer2, Injectable, ViewChild, TemplateRef
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import * as $ from 'jquery';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { Platform } from '@ionic/angular';
import { IonContent } from '@ionic/angular';
import { RoletaService } from 'src/app/services/roleta.service';

@Component({
  selector: 'app-home-social',
  templateUrl: './home-social.component.html',
  styleUrls: ['./home-social.component.scss'],
  providers: [Injectable]
})


export class HomeSocialComponent implements OnInit {

  @ViewChild('modalAcao', { static: false }) private modalAcao: TemplateRef<any>;
  @ViewChild('main', { static: false }) mainElement: ElementRef;

  arquivoFotoSelecionado: File | null = null;
  arquivoVideoSelecionado: File | null = null;
  descricaoPintura = '';
  fixed: boolean = false;

  showModal = false;
  showModalEdit = false;
  showModalFilter = false;
  modal_ativo: any;
  modal_banner_url: any;
  deferredPrompt: any;
  modal_ganhou: boolean = false;
  dynamicImageUrl: string;
  mostrarRoleta = false;
  tempoRestante = '';
  exibirTimer = false;
  is_loading = true;
  loading_top = false;
  loading_bottom = false;
  loading_global = false;
  mostrar_pwa = false;
  pwa_interval: any;
  is_desktop: boolean;

  // Variáveis do Usuário e Dados de Sessão
  user_id: string;
  token: string;
  user_name: string;
  foto_src = "https://cdn-icons-png.flaticon.com/512/149/149071.png";
  perfil: number = parseInt(localStorage.getItem('perfil') || '0');
  isEmbaixador: any;
  aniversario: boolean = false;
  travado = false;

  // Dados dos Posts e Filtros
  posts: any[] = [];
  todos_posts: any[] = [];
  post_edit: any = {};
  filtro_texto = '';
  id_grupo: any = null;
  clickedUserId = '';
  clickedUserName = '';
  clickedUserPhoto = '';
  clickedUserPosts = 0;
  clickedUserFollowers = 0;
  clickedUserEmbaixador = 0;
  isFollowingUser = false;
  isFiltrandoPorUsuario = false;
  isFiltrandoPorPost = false;

  // Dados de Pontos e Moedas
  pontos: number = parseInt(localStorage.getItem('pontos')) || 0;
  moedas: number = parseInt(localStorage.getItem('moedas')) || 0;
  pontos_ganhos: number;
  moedas_ganhos: number;

  constructor(private modalService: NgbModal, private sanitizer: DomSanitizer, private renderer: Renderer2,
    private http: HttpClient, private route: ActivatedRoute, private router: Router, private roletaService: RoletaService,
    private cdRef: ChangeDetectorRef, private elementRef: ElementRef, private platform: Platform) { 
    }

  @HostListener('window:beforeinstallprompt', ['$event'])
  onBeforeInstallPrompt(event: any) {
    event.preventDefault();
    this.deferredPrompt = event;
  }

  ngOnInit() {
    this.definirDadosUsuario();
    this.checarPerfil();
    this.definirAmbiente();
    this.inicializarVerificacoes();
    this.registrarAcesso();
    this.enviarUtmSource();
    this.verificarStatusRoleta();

    this.route.queryParams.subscribe(params => {
      const post_id = params['post_id'];
      if (post_id) {
        this.filtrarPorPost(post_id);
      } else {
        const id_participante = params['id_participante'];
        if (id_participante) {
          this.filtrarPorUsuario(id_participante);
        } else {
          this.obter_timeline(0, () => { this.set_autoplay(); });
        }
      }
    });
  }
  
  enviarUtmSource() {
    const utm_source = sessionStorage.getItem('utm_source');
    const utm_medium = sessionStorage.getItem('utm_medium');
    const utm_campaign = sessionStorage.getItem('utm_campaign');
  
    if (utm_source && this.user_id) {
      const body = {
        user_id: this.user_id,
        utm_source: utm_source,
        utm_medium: utm_medium || '', // Enviar vazio se não existir
        utm_campaign: utm_campaign || '' // Enviar vazio se não existir
      };
  
      this.http.post(`${environment.API_URL}acoes_mkt/insert`, body)
      .subscribe({
        next: (response) => {
          console.log('UTM data enviada com sucesso:', response);
          sessionStorage.removeItem('utm_source');
          sessionStorage.removeItem('utm_medium');
          sessionStorage.removeItem('utm_campaign');
        },
        error: (err) => {
          console.error('Erro ao enviar o UTM data:', err);
        },
        complete: () => {
          console.log('Requisição finalizada.');
        }
      });    
    }
  }

  private definirDadosUsuario() {
    this.user_id = localStorage.getItem('userId');
    this.token = localStorage.getItem('token');
    this.user_name = localStorage.getItem('userName');
    this.isEmbaixador = localStorage.getItem('isEmbaixador');
    if(localStorage.getItem('foto_src')) {
      this.foto_src = localStorage.getItem('foto_src');
    } else {
      this.foto_src = "https://cdn-icons-png.flaticon.com/512/149/149071.png";
    }
    this.aniversario = localStorage.getItem('aniversariante') ? true : false;
  }

  private checarPerfil() {
    if (this.perfil > 1) {
      this.router.navigate(['/promotores']);
    }
  }
  
  private definirAmbiente() {

    const rota: string = this.route.snapshot.url.toString();
    this.is_desktop = rota.includes('desktop');
    this.id_grupo = this.route.snapshot.paramMap.get('id_grupo');

    const id_seguindo = this.route.snapshot.paramMap.get('id');
    if( id_seguindo ){
      this.filtrarPorUsuario(id_seguindo);
    }

  }

  private inicializarVerificacoes() {

    if (localStorage.getItem('legado')) {
      this.travado = true;
      localStorage.removeItem('userId');
    }

    this.obter_timeline(0, () => { this.set_autoplay(); });
    this.obter_modal();

    this.pwa_interval = setInterval(()=>{ this.obter_pwa(); }, 2500)
    setTimeout(() => this.set_scroll(), 1000);
    setTimeout(() => this.checar_trava(), 5000);
  }

  filtrarPorUsuario(id_participante: string) {
    if (id_participante === this.user_id) {
      this.clickedUserName = this.user_name;
      this.clickedUserPhoto = this.foto_src || 'https://cdn-icons-png.flaticon.com/512/149/149071.png';
      this.isFollowingUser = false; 
    } else {
      this.http.get(environment.API_URL.concat('participantes_iclub/obter_dados/' + id_participante)).subscribe((data: any) => {
        this.clickedUserName = data.usuario.nome;
        this.clickedUserPhoto = data.usuario.foto || 'https://cdn-icons-png.flaticon.com/512/149/149071.png';
        this.clickedUserEmbaixador = data.usuario.embaixador;
        const scrollElement = this.elementRef.nativeElement.querySelector('main');
        scrollElement.scrollTo({ top: 0, behavior: 'smooth' });
      });
  
      this.http.get<any>(environment.API_URL.concat('sociais_iclub/verificar_seguindo/' + this.user_id + '/' + id_participante))
        .subscribe(data => {
          if (data.status === 1) {
            this.isFollowingUser = true; 
          } else {
            this.isFollowingUser = false;
          }
        }, error => {
          console.error('Erro ao verificar se está seguindo:', error);
          this.isFollowingUser = false;
        });
    }
  
    // Faz a requisição para obter o total de posts e seguidores do usuário
    this.http.get<any>(environment.API_URL.concat('sociais_iclub/obter_total_posts/' + id_participante))
      .subscribe(data => {
        this.clickedUserPosts = data.total_posts;
        this.clickedUserFollowers = data.total_followers;
      });
  
    // Continuar com o filtro de posts
    this.todos_posts = this.posts;
    this.posts = [];
    this.filtro_texto = '';
    this.isFiltrandoPorUsuario = true;
    this.clickedUserId = id_participante; // Armazena o ID do usuário clicado
  
    // Chama a função para obter a timeline filtrada por usuário
    this.obter_timeline(0, () => { this.set_autoplay(); }, id_participante);
  }

  filtrarPorPost(post_id: string) {
    this.posts = [];
    this.isFiltrandoPorUsuario = false;
    this.isFiltrandoPorPost = true;
    this.clickedUserId = '';
  
    this.obterPost(post_id, () => { this.set_autoplay(); });
  }

  obterPost(post_id: string, callback) {
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('user_id', this.user_id);
  
    this.loading_top = true;
  
    this.http.post<any>(environment.API_URL.concat('sociais_iclub/obter_post/' + post_id), formData).subscribe(
      (data: any) => {
        console.log('Resposta da API:', data); // Adicione este log para verificar a resposta
  
        if (data.status == 7) {
          alert('Sua conexão expirou, faça login novamente para continuar');
          localStorage.removeItem('token');
          localStorage.removeItem('userId');
          this.router.navigate(['/login']);
        }
  
        if (data.status === 1) {
          // Processa os dados do post
          const post = data.post;
  
          // Busca detalhes adicionais se necessário
          this.obterDetalhesParticipante(post);
          this.verificarSeguindo(post);
  
          // Atribui o post único ao array de posts
          this.posts = [post];
  
          this.cdRef.detectChanges();
        } else {
          // Trata erro ou post não encontrado
          alert('Post não encontrado.');
        }
  
        this.loading_top = false;
  
        callback();
      },
      error => {
        console.error('Erro ao buscar o post:', error);
        this.loading_top = false;
        callback();
      }
    );
  }  
  
  set_scroll() {
    const options = {
      root: null, // O elemento root será o viewport
      rootMargin: '0px', // Margem ao redor do elemento root
      threshold: 1 // Porcentagem de visibilidade necessária para disparar o evento
    };
  
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // O elemento está visível na viewport, chame a função obter_timeline()
          const offset = this.posts.length;
          if (offset > 0) {
            // Verifica se o filtro de usuário está ativo e mantém o filtro durante a rolagem
            this.obter_timeline(offset, () => { this.set_autoplay(); }, this.clickedUserId);
          }
        }
      });
    }, options);
  
    const viewportElement = this.elementRef.nativeElement.querySelector('#fim_timeline');
    observer.observe(viewportElement);
  }

  set_autoplay() {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5 // Altere este valor conforme necessário (de 0 a 1)
    };

    const callback = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const videoElement = entry.target.querySelector('video');
          if (videoElement) {
            console.log(videoElement);
            videoElement.play(); // Inicia a reprodução do vídeo
          }
        } else {
          const videoElement = entry.target.querySelector('video');
          if (videoElement) {
            console.log(videoElement);
            videoElement.pause(); // Pausa a reprodução do vídeo
          }
        }
      });
    };

    this.posts.forEach(post => {
      const postElement = document.getElementById('post-' + post.id);
      const observer = new IntersectionObserver(callback, options);
      observer.observe(postElement);
    });
  }

  private registrarAcesso() {
    this.http.get<any>(environment.API_URL.concat('acoes_iclub/registrar_acesso/'+this.user_id));
  }

  obter_pwa() {
    const isInStandaloneMode = () =>
      window.matchMedia('(display-mode: standalone)').matches || (window.navigator as any).standalone;
  
    const isIOS = this.platform.is('ios');
  
    console.log(isInStandaloneMode(), isIOS);
    if (!isInStandaloneMode() && !isIOS && this.deferredPrompt) {
      this.mostrar_pwa = true;
      clearInterval(this.pwa_interval);
    }
  }

  responder_pwa(resposta) {
    switch (resposta) {
      case 'nao':
        localStorage.setItem('resposta_pwa', 'nao');
        this.mostrar_pwa = false;
        break;
      case 'sim':
        localStorage.setItem('resposta_pwa', 'sim');
        this.mostrar_pwa = false;
        this.add_pwa();
        break;
    }
  }

  add_pwa() {
    if (this.deferredPrompt) {
      this.deferredPrompt.prompt();
      this.deferredPrompt.userChoice
        .then((choiceResult: any) => {
          if (choiceResult.outcome === 'accepted') {
            console.log('O aplicativo foi instalado');
          } else {
            console.log('O usuário cancelou a instalação');
          }
          this.deferredPrompt = null;
        });
    } else {
      console.error('O prompt de instalação não está disponível.');
      // Possivelmente reativar a função com uma mensagem de erro
    }
  }

  abrir_modal_acao() {
    if (!this.modalService.hasOpenModals()) {
      this.modalService.open(this.modalAcao, { windowClass: 'modal-acao', size: 'md' });
    }
  }

  obter_modal() {
    this.http.post<any>(environment.API_URL.concat('acoes/modal_iclub'), {
      id_usuario: this.user_id
    }).subscribe((data: any) => {
      if (data.status == 1) {

        if (localStorage.getItem('viumodal_' + data.modal.titulo) === '01') {
          //return;
        }
        console.log(data.modal);
        this.modal_ativo = data.modal;
        this.modal_banner_url = this.sanitizer.bypassSecurityTrustResourceUrl(this.modal_ativo.banner);
        setTimeout(() => {
          this.abrir_modal_acao();
        }, 2000);

        localStorage.setItem('viumodal_' + data.modal.titulo, '01');

      }
    });
  }

  obter_timeline(offset: number, callback, filter_id_participante?: string) {
    const formData = new FormData();
  
    formData.append('token', localStorage.getItem('token'));
    formData.append('user_id', this.user_id);
  
    const userIdFiltrado = filter_id_participante || this.clickedUserId;
    if (userIdFiltrado) {
      formData.append('id_participante', userIdFiltrado);
    }
  
    if (this.id_grupo) {
      formData.append('id_grupo', this.id_grupo);
    }
  
    if (this.loading_top || this.loading_bottom) {
      return; 
    }
  
    if (offset === 0) {
      this.loading_top = true;
    } else {
      this.loading_bottom = true;
    }
  
    let url = 'sociais_iclub/obter_timeline/' + offset;
    if (this.filtro_texto) {
      url = url.concat('?filtro=' + this.filtro_texto);
    }
  
    this.http.post<any>(environment.API_URL.concat(url), formData).subscribe(
      (data: any) => {
        if (data.status == 7) {
          alert('Sua conexão expirou, faça login novamente para continuar');
          localStorage.removeItem('token');
          localStorage.removeItem('userId');
          this.router.navigate(['/login']);
        }
  
        if (data.status === 1) {
          if (userIdFiltrado) {
            // Garante que apenas posts do usuário filtrado serão exibidos
            const filteredPosts = data.posts;
            
            filteredPosts.forEach(post => {
              this.obterDetalhesParticipante(post);  
              this.verificarSeguindo(post);  
            });
  
            if (offset > 0) {
              this.posts = this.posts.concat(filteredPosts);
            } else {
              this.posts = filteredPosts;
            }
  
          } else {
            data.posts.forEach(post => {
              this.obterDetalhesParticipante(post); 
              this.verificarSeguindo(post);  
            });
  
            if (offset > 0) {
              this.posts = this.posts.concat(data.posts);
            } else {
              this.posts = data.posts;
            }
          }
  
          this.cdRef.detectChanges();
        }
  
        this.loading_bottom = false;
        this.loading_top = false;
        this.is_loading = false;
        callback();
      },
      error => {
        console.error('Erro ao buscar posts:', error);
        this.loading_top = false;
        this.loading_bottom = false;
        callback();
      }
    );
  }
  
  obterDetalhesParticipante(post) {
    // Verifica se existe um item no localStorage atrelado ao post.id_participante
    const itemKey = 'embaixador_'.concat(post.id_participante);
    const localData = localStorage.getItem(itemKey);

    if(post.id_participante === this.user_id){
      post.embaixador = 0;
      return;
    }

    if (localData == 'S') {
        post.embaixador = 1;
        return;
    } else {
      // Caso contrário, realiza a requisição HTTP
      this.http.get<any>(environment.API_URL.concat('sociais_iclub/obter_detalhes_participante/' + post.id_participante))
          .subscribe(data => {
            if (data.status === 1) {
                post.embaixador = data.participante.embaixador;

                if (data.participante.embaixador) {
                    localStorage.setItem(itemKey, 'S');
                }
            }
        });
    }
  }

  getFirstTwoNames(fullName: string): string {
    if (!fullName) return '';
    return fullName.split(' ').slice(0, 2).join(' ');
  }  

  seguirUsuario(post) {
    console.log('Seguindo o usuário:', post.id_participante);  // Adiciona um log para ver se está sendo chamado
  
    const formData = new FormData();
    formData.append('id_seguidor', this.user_id);
    formData.append('id_seguido', post.id_participante);
  
    this.http.post<any>(environment.API_URL.concat('sociais_iclub/seguir_usuario'), formData)
      .subscribe(data => {
        if (data.status === 1) {
          this.posts.filter(p => p.id_participante == post.id_participante).forEach(p => p.seguindo = true);
          localStorage.setItem(`seguindo_${post.id_participante}`, 'S');

          if (post.id_participante === this.clickedUserId) {
            this.isFollowingUser = true;
            this.clickedUserFollowers += 1;
          }

        } else {
          //console.log('Erro ao seguir o usuário:', data);
        }
      }, error => {
        //console.error('Erro na requisição:', error);
      });
  }

  seguirUsuarioPerfil() {
    const formData = new FormData();
    formData.append('id_seguidor', this.user_id);
    formData.append('id_seguido', this.clickedUserId);
  
    this.http.post<any>(environment.API_URL.concat('sociais_iclub/seguir_usuario'), formData)
      .subscribe(data => {
        if (data.status === 1) {
          console.log('Seguido com sucesso!');
          this.isFollowingUser = true;
          this.clickedUserFollowers += 1;

          this.posts.filter(p => p.id_participante == this.clickedUserId).forEach(p => p.seguindo = true);
        } else {
          alert('Erro ao seguir o usuário: ' + data.error);
        }
      }, error => {
        console.error('Erro na requisição:', error);
      });
  }
   
  deixarDeSeguirUsuario(post) {
    console.log('Deixando de seguir o usuário:', post.id_participante);  // Verificação
    
    const formData = new FormData();
    formData.append('id_seguidor', this.user_id);
    formData.append('id_seguido', post.id_participante);
  
    this.http.post<any>(environment.API_URL.concat('sociais_iclub/deixar_seguir_usuario'), formData)
      .subscribe(data => {
        if (data.status === 1) {
          console.log('Deixou de seguir com sucesso!');
          this.posts.filter(p => p.id_participante == post.id_participante).forEach(p =>{ p.seguindo = false; p.showOptions = false });
          localStorage.removeItem(`seguindo_${post.id_participante}`);

          if (post.id_participante === this.clickedUserId) {
            this.isFollowingUser = false;
            this.clickedUserFollowers -= 1; 
          }
        } else {
          //console.log('Erro ao deixar de seguir o usuário:', data);
        }
      }, error => {
       // console.error('Erro na requisição:', error);
      });
  }

  verificarSeguindo(post) {

    if(post.id_participante == this.user_id){
      post.seguindo = false;
      return;
    }

    // Verifica se existe um item no localStorage atrelado ao post.id_participante
    const itemKey = 'seguindo_'.concat(post.id_participante);
    const localData = localStorage.getItem(itemKey);
    

    if (localData == 'S') {
        // Se o item existe e o valor é "S", marca o post como seguindo = true
        post.seguindo = true;
        return;
    } else {
        // Caso contrário, realiza a requisição HTTP
        this.http.get<any>(environment.API_URL.concat('sociais_iclub/verificar_seguindo/' + this.user_id + '/' + post.id_participante))
            .subscribe(data => {
                if (data.status === 1) {
                    post.seguindo = true;

                    // Marca o item no localStorage como "S" se seguir for verdadeiro
                    localStorage.setItem(itemKey, 'S');
                } else {
                    post.seguindo = false;
                }
            });
    }
  }

  voltarParaTodosPosts() {
    // Reseta todos os estados relacionados ao filtro por usuário
    this.posts = [];
    this.filtro_texto = ''; 
    this.isFiltrandoPorUsuario = false;
    this.isFiltrandoPorPost = false;
    // Limpar as informações do usuário clicado
    this.clickedUserId = '';
    this.clickedUserName = '';
    this.clickedUserPhoto = '';
    this.clickedUserPosts = 0;
  
    // Recarrega a timeline sem filtros
    this.obter_timeline(0, () => { this.set_autoplay(); });
  }

  filtrar() {
    this.showModalFilter = false;
    this.posts = [];

    if (this.travado) {
      alert('O regulamento do programa foi atualizado, por favor atualize seus dados para continuar.');
      this.router.navigate(['/cadastro', { legado: 'legado' }]);
    }

    this.obter_timeline(0, () => { this.set_autoplay(); });
  }

  limpar_filtros() {
    this.showModalFilter = false;
    this.filtro_texto = '';
    this.posts = [];

    this.obter_timeline(0, () => { this.set_autoplay(); });

  }

  onFileSelected(event: any, tipo: string) {
    const arquivoSelecionado = event.target.files[0];
    this.arquivoFotoSelecionado = null;
    this.arquivoVideoSelecionado = null;
    if (tipo === 'foto') {
      this.arquivoFotoSelecionado = arquivoSelecionado;
    } else if (tipo === 'video') {
      this.arquivoVideoSelecionado = arquivoSelecionado;
    }
  }

  async postar() {

    if (this.checar_trava()) { return }
    
    if(this.loading_global){
      return;
    }

    this.loading_global = true;

    const formData = new FormData();
    this.filtro_texto = '';

    console.log(this.arquivoFotoSelecionado);
    if (this.arquivoFotoSelecionado) {
      formData.append('imagem', this.arquivoFotoSelecionado);
    }

    if (this.arquivoVideoSelecionado) {
      formData.append('video', this.arquivoVideoSelecionado);
    }

    if (this.fixed){
      formData.append('fixed', '1');
    }

    if (!this.arquivoFotoSelecionado && !this.arquivoVideoSelecionado && !this.descricaoPintura) {
      alert("Selecione Foto, Vídeo ou escreva algo interessante...");
      this.loading_global = false;
      return;
    }

    const palavrasEncontradas = await this.contemPalavrasCensuradas(this.descricaoPintura);
    if (palavrasEncontradas.length > 0) {
      alert(`O texto informado contém palavras censuradas: ${palavrasEncontradas.join(', ')}`);
      this.loading_global = false;
      return;
    }

    formData.append('text', this.descricaoPintura);
    formData.append('id_participante', this.user_id);
    formData.append('token', localStorage.getItem('token'));


    if (this.id_grupo) {
      formData.append('id_grupo', this.id_grupo);
    }

    this.toggleModal();
    this.loading_top = true;

    this.http.post<any>(environment.API_URL.concat('sociais_iclub/postar'), formData)
      .subscribe(response => {
        this.loading_top = false;
        this.loading_global = false;

        if(response.pontos){

          this.pontos_ganhos = response.pontos;
          this.animacao_saldo();
          // this.dynamicImageUrl = '/assets/win_post.png'
          // let efeito_sonoro = new Audio("assets/audio/positivo.wav");
          // efeito_sonoro.play(); 
        }

        if (response.status == 0) {
          alert("Erro: " + response.error);
        }
        if (response.status == 1) {
          this.obter_timeline(0, () => { this.set_autoplay(); });
        }
        // Faça qualquer ação adicional necessária
        this.descricaoPintura = '';
      }, error => {
        this.loading_global = false;
        this.loading_top = false;
        console.error('Erro ao enviar os dados:', error);
        console.log(error);
      });
  }

  async contemPalavrasCensuradas(descricao: string): Promise<string[]> {
    let palavrasCensuradasEncontradas: string[] = [];
    try {
      const data: any[] = await this.http.get<any[]>(environment.API_URL.concat('censuras_iclub/obter_censuras')).toPromise();
  
      data.forEach(item => {
        if (item && item.termos) {
          try {
            var termosArray = JSON.parse(item.termos);
            if (Array.isArray(termosArray)) {
              termosArray.forEach((palavra: string) => {
                if (descricao.toLocaleLowerCase().includes(palavra.toLocaleLowerCase())) {
                  palavrasCensuradasEncontradas.push(palavra);
                }
              });
            } else {
              console.log("O campo 'termos' não é um array válido.");
            }
          } catch (error) {
            console.log("Termos como string:", item.termos);
          }
        } else {
          console.log("A propriedade 'termos' não está presente nos dados recebidos.");
        }
      });
  
      return palavrasCensuradasEncontradas;
    } catch (error) {
      console.error('Erro ao obter censuras:', error);
      return palavrasCensuradasEncontradas; // Ou trate o erro de acordo com sua necessidade
    }
  }

  editar_post() {
    const formData = new FormData();
    this.filtro_texto = '';

    console.log(this.arquivoFotoSelecionado);
    if (this.arquivoFotoSelecionado) {
      formData.append('imagem', this.arquivoFotoSelecionado);
    }

    if (this.arquivoVideoSelecionado) {
      formData.append('video', this.arquivoVideoSelecionado);
    }

    if (!this.arquivoFotoSelecionado && !this.arquivoVideoSelecionado && !this.post_edit.text) {
      alert('Selecione Foto, Vídeo ou escreva algo interessante...');
      return;
    }

    formData.append('text', this.post_edit.text);
    formData.append('id_participante', this.user_id);
    formData.append('token', localStorage.getItem('token'));
    formData.append('id_post', this.post_edit.id);

    this.loading_top = true;
    this.toggleModalEdit();

    this.http.post<any>(environment.API_URL.concat('sociais_iclub/postar'), formData)
      .subscribe(response => {
        this.loading_top = false;

        if (response.status === 0) {
          alert('Erro: ' + response.error);
        }
        if (response.status === 7) {
          alert('Sua conexão expirou, faça login novamente para continuar');
          localStorage.removeItem('token');
          localStorage.removeItem('userId');
          this.router.navigate(['/login']);
        }
        if (response.status === 1) {

          const index = this.posts.findIndex(p => p.id === this.post_edit.id); // Encontra o índice do item com o ID desejado
          if (index !== -1) {
            this.posts[index] = response.post; // Substitui o item pelo novo objeto
            this.posts[index].showOptions = false;
          } else {
            console.log('Item não encontrado'); // Caso o item não seja encontrado
          }
        }
        // Faça qualquer ação adicional necessária
        this.post_edit = [];
      }, error => {
        console.error('Erro ao enviar os dados:', error);
        console.log(error);
        // Trate o erro de acordo com sua necessidade
      });
  }

  fixPost(post){
    const formData = new FormData();
    this.filtro_texto = '';

    formData.append('id_participante', this.user_id);
    formData.append('token', localStorage.getItem('token'));
    formData.append('id_post', post.id);

    console.log(post);

    if(post.fixed == '1'){
      formData.append('fixed', '0');
    }
    else{
      formData.append('fixed', '1');
    }

    this.loading_top = true;

    this.http.post<any>(environment.API_URL.concat('sociais_iclub/fixarPost'), formData)
      .subscribe(response => {
        this.loading_top = false;

        if (response.status === 0) {
          alert('Erro: ' + response.error);
        }
        if (response.status === 7) {
          alert('Sua conexão expirou, faça login novamente para continuar');
          localStorage.removeItem('token');
          localStorage.removeItem('userId');
          this.router.navigate(['/login']);
        }
        if (response.status === 1) {

          const index = this.posts.findIndex(p => p.id === post.id); // Encontra o índice do item com o ID desejado
          if (index !== -1) {

            this.posts[index].fixed = post.fixed == '1' ? '0' : '1';
            this.posts[index].showOptions = false;
          } else {
            console.log('Item não encontrado'); // Caso o item não seja encontrado
          }
        }
        // Faça qualquer ação adicional necessária
        this.post_edit = [];
      }, error => {
        console.error('Erro ao enviar os dados:', error);
        console.log(error);
        // Trate o erro de acordo com sua necessidade
      });
  }

  async comentar($event: any) {

    if (this.checar_trava()) { return }

    if(this.loading_global){
      return;
    }

    this.loading_global = true;

    let event = $event.$event;
    let content = $event.content;
    let tipo = $event.type;

    const button = event.target;
    const textarea = button.parentNode.querySelector('.comment-input') as HTMLTextAreaElement;
    const comentario = textarea.value;

    if (!comentario || comentario.trim() == '') {
      alert("Escreva algo legal"); 
      this.loading_global = false;
      return;
    }

    const palavrasEncontradas = await this.contemPalavrasCensuradas(comentario);
    if (palavrasEncontradas.length > 0) {
      alert(`O texto informado contém palavras censuradas: ${palavrasEncontradas.join(', ')}`);
      this.loading_global = false;
      return;
    }

    const formData = new FormData();

    formData.append('text', comentario);
    formData.append('id_participante', this.user_id);
    formData.append('token', localStorage.getItem('token'));

    if (tipo == 'post') {
      formData.append('id_post', content.id);
    }
    if (tipo == 'comment') {
      formData.append('id_comment', content.id);
      formData.append('id_post', content.id_post);
    }

    console.log('Enviando dados:', {
      text: comentario,
      id_participante: this.user_id,
      token: localStorage.getItem('token'),
      id_post: content.id,
      id_comment: tipo == 'comment' ? content.id : null
    });


    this.http.post<any>(environment.API_URL.concat('sociais_iclub/comentar'), formData, { responseType: 'text' as 'json' })
    .subscribe(response => {
      try {
        const jsonResponse = JSON.parse(response);
        if (jsonResponse.status == 1) {
          textarea.value = '';
          this.loading_global = false;
          let index;
          if (tipo == 'post') {
            index = this.posts.findIndex(p => p.id === content.id);
          } else {
            index = this.posts.findIndex(p => p.id === content.id_post);
          }
  
          if (jsonResponse.pontos) {
            this.pontos_ganhos = jsonResponse.pontos;
            this.animacao_saldo();
          }
  
          if (index !== -1) {
            this.posts[index] = jsonResponse.post;
            this.posts[index]['showPostComments'] = true;
          } else {
            console.log('Item não encontrado');
          }
        }
      } catch (e) {
        this.loading_global = false;
        console.error('Erro ao analisar a resposta do servidor:', e);
        console.log('Resposta do servidor:', response);
      }
    }, error => {
      this.loading_global = false;
      console.error('Erro ao enviar os dados:', error);
    });
  
  }

  reactPost(post: any, reaction: string): void {
    if (this.checar_trava()) { return; }

    if (!post.selectedReaction) { //Se não tiver nenhuma reação selecionada, adiciona +1 na reação selecionada
      post.selectedReaction = reaction;
      post.reactions[reaction] += 1;

      this.create_update_reaction('post', reaction, post.id);
    }
    else if (post.selectedReaction !== reaction) {
      post.reactions[post.selectedReaction] -= 1;
      post.reactions[reaction] += 1;
      post.selectedReaction = reaction;

      this.create_update_reaction('post', reaction, post.id);
    }
    else { // Se a reação selecionada for igual a reação que já estava selecionada, remove a reação selecionada
      post.reactions[post.selectedReaction] -= 1;
      post.selectedReaction = null;

      this.remove_reaction('post', post.id);
    }
    let efeito_sonoro = new Audio("assets/audio/react.wav");
    efeito_sonoro.play(); 
  }

  reactComment($event): void {
    if (this.checar_trava()) { return }

    const comment = $event.comment;
    const reaction = $event.type;

    if (!comment.selectedReaction) { //Se não tiver nenhuma reação selecionada, adiciona +1 na reação selecionada
      this.create_update_reaction('comment', reaction, comment.id_post, comment.id);

      comment.selectedReaction = reaction;
      comment.reactions[reaction] += 1;

    }
    else if (comment.selectedReaction !== reaction) {
      this.create_update_reaction('comment', reaction, comment.id_post, comment.id);

      comment.reactions[comment.selectedReaction] -= 1;
      comment.reactions[reaction] += 1;
      comment.selectedReaction = reaction;

    }
    else { // Se a reação selecionada for igual a reação que já estava selecionada, remove a reação selecionada
      comment.reactions[comment.selectedReaction] -= 1;
      comment.selectedReaction = null;

      this.remove_reaction('comment', comment.id);
    }
    let efeito_sonoro = new Audio("assets/audio/react.wav");
    efeito_sonoro.play(); 
  }

  create_update_reaction(type, reaction, id_post, id_comment = null) {
    if (this.checar_trava()) { return }

    const formData = new FormData();

    formData.append('type', reaction);
    formData.append('token', localStorage.getItem('token'));
    formData.append('id_participante', this.user_id);
    formData.append('id_post', id_post);

    if (type == 'comment')
      formData.append('id_comment', id_comment);


    this.http.post<any>(environment.API_URL.concat('sociais_iclub/reagir'), formData)
      .subscribe(data => {
        if (data.status == 0) {
          alert("Erro: " + data.error);
        }
        if (data.status == 7) {
          alert("Sua conexão expirou, faça login novamente para continuar");
          localStorage.removeItem('token');
          localStorage.removeItem('userId');
          this.router.navigate(['/login']);
        }
        if (data.status == 1) {
          console.log(id_post);
          const index = this.posts.findIndex(p => p.id === id_post); // Encontra o índice do item com o ID desejado

          if (index !== -1) {
            this.posts[index]['reactions'] = data.post.reactions; // Substitui o item pelo novo objeto
            this.posts[index]['showPostComments'] = true;

            if (type == 'comment') {
              const c = this.posts[index]['comments'].findIndex(c => c.id === id_comment);
              console.log(c);
              this.posts[index]['comments'] = data.post.comments;
              this.posts[index]['comments'][c]['showReactions'] = true;
              console.log(this.posts[index]);
            }
          } else {
            console.log('Item não encontrado'); // Caso o item não seja encontrado
          }

        }
      });
  }

  remove_reaction(type, id) {
    const formData = new FormData();

    formData.append('token', localStorage.getItem('token'));
    formData.append('id_participante', this.user_id);

    switch (type) {
      case "post":
        formData.append('id_post', id);
        break;
      case "comment":
        formData.append('id_comment', id);
        break;
    }

    this.http.post<any>(environment.API_URL.concat('sociais_iclub/remover_reacao'), formData)
      .subscribe(data => {
        if (data.status == 0) {
          alert("Erro: " + data.error);
        }
        if (data.status == 7) {
          alert("Sua conexão expirou, faça login novamente para continuar");
          localStorage.removeItem('token');
          localStorage.removeItem('userId');
          this.router.navigate(['/login']);
        }
        if (data.status == 1) {
          console.log('sucesso');
        }
      });
  }

  denunciar(type, content) {
    if (this.checar_trava()) { return }

    const formData = new FormData();

    formData.append('token', localStorage.getItem('token'));
    formData.append('id_participante', this.user_id);
    content.showOptions = false;

    if (type == 'comment') {
      formData.append('id_comment', content.id);
      formData.append('id_post', content.id_post);
    }
    else {
      formData.append('id_post', content.id);
    }

    this.loading_global = true;

    this.http.post<any>(environment.API_URL.concat('sociais_iclub/denunciar'), formData)
      .subscribe(data => {
        if (data.status == 0) {
          alert("Erro: " + data.error);
        }
        if (data.status == 7) {
          alert("Sua conexão expirou, faça login novamente para continuar");
          localStorage.removeItem('token');
          localStorage.removeItem('userId');
          this.router.navigate(['/login']);
        }
        if (data.status == 1) {
          alert("Denuncia realizada, iremos avaliar sua denuncia");
        }
        this.loading_global = false;
      });
  }

  deletar_post(content) {

    content.showOptions = false;

    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('id_participante', this.user_id);
    formData.append('id_post', content.id);

    this.loading_global = true;

    this.http.post<any>(environment.API_URL.concat('sociais_iclub/deletar'), formData)
      .subscribe(data => {
        if (data.status == 0) {
          alert("Erro: " + data.error);
        }
        if (data.status == 7) {
          alert("Sua conexão expirou, faça login novamente para continuar");
          localStorage.removeItem('token');
          localStorage.removeItem('userId');
          this.router.navigate(['/login']);
        }
        if (data.status == 1) {
          alert("Post apagado com sucesso");
          this.obter_timeline(0, () => { });
        }
        this.loading_global = false;
      });
  }

  deletar_comment($event) {

    const content = $event.content;
    content.showOptions = false;

    const formData = new FormData();

    formData.append('token', localStorage.getItem('token'));
    formData.append('id_participante', this.user_id);
    formData.append('id_comment', content.id);
    formData.append('id_post', content.id_post);

    this.loading_global = true;

    this.http.post<any>(environment.API_URL.concat('sociais_iclub/deletar'), formData)
      .subscribe(data => {
        if (data.status == 0) {
          alert("Erro: " + data.error);
        }
        if (data.status == 7) {
          alert("Sua conexão expirou, faça login novamente para continuar");
          localStorage.removeItem('token');
          localStorage.removeItem('userId');
          this.router.navigate(['/login']);
        }
        if (data.status == 1) {

          let index = this.posts.findIndex(p => p.id === content.id_post); // Encontra o índice do item com o ID desejado

          if (index !== -1) {
            this.posts[index] = data.post; // Substitui o item pelo novo objeto
            this.posts[index]['showPostComments'] = true;
          } else {
            console.log('Item não encontrado'); // Caso o item não seja encontrado
          }
        }

        this.loading_global = false;
      });
  }

  toggleReactions(post: any): void {
    post.showReactions = !post.showReactions;
  }

  toggleComments(post: any): void {
    post.showComments = !post.showComments;
  }

  togglePostComments(post: any): void {
    post.showPostComments = !post.showPostComments;
  }

  toggleCommentReactions(comment: any): void {
    comment.showReactions = !comment.showReactions;
  }

  toggleDenunciation(content: any): void {
    content.showOptions = !content.showOptions;
  }

  toggleModal(): void {
    this.arquivoFotoSelecionado = null;
    this.arquivoVideoSelecionado = null;
    this.showModal = !this.showModal;
  }

  toggleModalEdit(post: any = []): void {
    this.arquivoFotoSelecionado = null;
    this.arquivoVideoSelecionado = null;
    this.post_edit = post;
    this.showModalEdit = !this.showModalEdit;

    if (post.showOptions) {
      post.showOptions = false;
    }
  }

  toggleModalFilter(): void {
    this.showModalFilter = !this.showModalFilter;
  }

  terminar_cadastro() {
    this.router.navigate(['cadastro']);
  }

  checar_trava() {
    if (this.travado) {
      alert('O regulamento do programa foi atualizado, por favor atualize seus dados para continuar.');
      const isDesktopRoute = this.router.url.includes('-desktop');
      const currentRoute = this.router.url.replace('-desktop', '');
      const routeDesktop = currentRoute + '-desktop';

      if ($(window).width() < 780) {
        this.router.navigate(['/cadastro', { legado: 'legado' }]).then(() => { location.reload(); });
      } else if ($(window).width() > 780) {
        this.router.navigate(['/cadastro-desktop', { legado: 'legado' }]).then(() => { location.reload(); });
      }
      return true;
    }

    if (!this.user_id) {
      alert('Sua sessão encerrou, faça login novamente para continuar');
      this.router.navigate(['/login']);
      return true;
    }
  }

  animacao_saldo() {
    this.modal_ganhou = true;
    setTimeout(() => { this.modal_ganhou = false }, 4000);
  }

  atualizar_saldos($event) {
    setTimeout(() => {
      this.modal_ganhou = false;
    }, 3000);
  }

  verificarStatusRoleta() {
    const userId = localStorage.getItem('userId');
    if (userId) {
      this.roletaService.verificarStatusRoleta(userId).subscribe(response => {
        if (response.status === 'success') {
          this.mostrarRoleta = true;
          this.exibirTimer = false;
        } else if (response.status === 'error' && response.tempoRestante) {
          this.mostrarRoleta = false;
          this.tempoRestante = response.tempoRestante;
          this.exibirTimer = true;
        }
      }, error => {
        console.error('Erro ao verificar o status da roleta:', error);
      });
    }
  }

  fecharModal() {
    this.mostrarRoleta = false; 
  }

}
