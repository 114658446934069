import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { CadastroComponent } from './paginas/cadastro/cadastro.component';
import { LoginComponent } from './paginas/login/login.component';
import { PrimeiroAcessoComponent } from './paginas/primeiro-acesso/primeiro-acesso.component';
import { PerfilUsuarioComponent } from './paginas/perfil-usuario/perfil-usuario.component';
import { FaqComponent } from './paginas/faq/faq.component';
import { DescontosComponent } from './paginas/descontos/descontos.component';
import { LandingComponent } from './paginas/landing/landing.component';
import { HomeSocialComponent } from './paginas/home-social/home-social.component';
import { GruposComponent } from './paginas/grupos/grupos.component';
import { EscolaComponent } from './paginas/escola/escola.component';
import { EnqueteComponent } from './paginas/enquete/enquete.component';
import { AniversariantesComponent } from './paginas/aniversariantes/aniversariantes.component';
import { DriveComponent } from './paginas/drive/drive.component';
import { AniversarianteComponent } from './paginas/aniversariante/aniversariante.component';
import { CertificadoComponent } from './paginas/certificado/certificado.component';
import { FaleConoscoComponent } from './paginas/fale-conosco/fale-conosco.component';
import { TesteWebflowComponent } from './paginas/teste-webflow/teste-webflow.component';
import { CuidaComponent } from './paginas/cuida/cuida.component';
import { PromotoresComponent } from './paginas/promotores/promotores.component';
import { IndiqueComponent } from './paginas/indique/indique.component';
import { RankingPromotoresComponent } from './paginas/ranking-promotores/ranking-promotores.component';
import { TeleporthComponent } from './paginas/teleporth/teleporth.component';
import { TreinamentosComponent } from './paginas/treinamentos/treinamentos.component';
import { VitrineComponent } from './paginas/vitrine/vitrine.component';
import { SeguindoComponent } from './paginas/seguindo/seguindo.component';
import { EmbaixadoresComponent } from './paginas/embaixadores/embaixadores.component';

const routes: Routes = [
  {    path: 'folder/:id',        loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)  },
  {    path: '',                  component: LoginComponent},

  {    path: 'login',             component: LoginComponent  },
  {    path: 'login/:cpf',        component: LoginComponent  },
  {    path: 'vitrine',           component: VitrineComponent  },
  {    path: 'cadastro',          component: CadastroComponent  },
  {    path: 'cadastro/:legado',  component: CadastroComponent  },
  {    path: 'indicacao/:id',     component: CadastroComponent  },
  {    path: 'cadastrar',         component: CadastroComponent  },
  {    path: 'cadastrar/:cpf',    component: CadastroComponent  },
  {    path: 'home',              component: HomeSocialComponent  },
  {    path: 'home/:id_grupo',    component: HomeSocialComponent  },
  {    path: 'home-perfil/:id',   component: HomeSocialComponent  },
  {    path: 'perfil-usuario',    component: PerfilUsuarioComponent},
  {    path: 'grupos',            component: GruposComponent},
  {    path: 'faq',               component: FaqComponent},
  {    path: 'escola',            component: EscolaComponent},
  {    path: 'treinamentos',      component: TreinamentosComponent},
  {    path: 'cuida',             component: CuidaComponent},
  {    path: 'enquete',           component: EnqueteComponent},
  {    path: 'drive',             component: DriveComponent},
  {    path: 'descontos',         component: DescontosComponent},
  {    path: 'aniversariante',    component: AniversarianteComponent},
  {    path: 'aniversariantes',   component: AniversariantesComponent},
  {    path: 'landing',           component: TeleporthComponent},
  {    path: 'primeiro-acesso',   component: PrimeiroAcessoComponent},
  {    path: 'fale-conosco',      component: FaleConoscoComponent},
  {    path: 'indique',           component: IndiqueComponent},
  {    path: 'vitrine',           component: VitrineComponent},
  {    path: 'seguindo',          component: SeguindoComponent},
  {    path: 'embaixadores',      component: EmbaixadoresComponent},
  

  {    path: 'login-desktop',             component: LoginComponent  },
  {    path: 'vitrine-desktop',           component: VitrineComponent  },
  {    path: 'cadastro-desktop',          component: CadastroComponent  },
  {    path: 'cadastro-desktop/:legado',  component: CadastroComponent  },
  {    path: 'indicacao-desktop/:id',     component: CadastroComponent  },
  {    path: 'cadastrar-desktop',         component: CadastroComponent  },
  {    path: 'cadastrar-desktop/:cpf',    component: CadastroComponent  },
  {    path: 'home-desktop',              component: HomeSocialComponent  },
  {    path: 'home-desktop/:id_grupo',    component: HomeSocialComponent  },
  {    path: 'home-perfil-desktop/:id',   component: HomeSocialComponent  },
  {    path: 'perfil-usuario-desktop',    component: PerfilUsuarioComponent},
  {    path: 'grupos-desktop',            component: GruposComponent},
  {    path: 'faq-desktop',               component: FaqComponent},
  {    path: 'escola-desktop',            component: EscolaComponent},
  {    path: 'treinamentos-desktop',      component: TreinamentosComponent},
  {    path: 'cuida-desktop',             component: CuidaComponent},
  {    path: 'enquete-desktop',           component: EnqueteComponent},
  {    path: 'drive-desktop',             component: DriveComponent},
  {    path: 'descontos-desktop',         component: DescontosComponent},
  {    path: 'aniversariante-desktop',    component: AniversarianteComponent},
  {    path: 'aniversariantes-desktop',   component: AniversariantesComponent},
  {    path: 'landing-desktop',           component: LandingComponent},
  {    path: 'primeiro-acesso-desktop',   component: PrimeiroAcessoComponent},
  {    path: 'fale-conosco-desktop',      component: FaleConoscoComponent},
  {    path: 'indique-desktop',           component: IndiqueComponent},
  {    path: 'vitrine-desktop',           component: VitrineComponent},
  {    path: 'seguindo-desktop',          component: SeguindoComponent},
  {    path: 'embaixadores-desktop',      component: EmbaixadoresComponent},


  {    path: 'certificado',               component: CertificadoComponent},
  {    path: 'webflow',                   component: TesteWebflowComponent},

  {    path: 'promotores',                component: PromotoresComponent},
  {    path: 'ranking-promotores',        component: RankingPromotoresComponent},
  {    path: 'promotores-desktop',        component: PromotoresComponent},
  {    path: 'ranking-promotores-desktop',component: RankingPromotoresComponent},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      onSameUrlNavigation: 'reload' })
  ],
  exports: [RouterModule]
})

export class AppRoutingModule {}
