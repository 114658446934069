import { Component, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Platform } from '@ionic/angular';

import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';

//import { SplashScreen } from '@ionic-native/splash-screen/ngx';
//import { StatusBar } from '@ionic-native/status-bar/ngx';
import * as $ from 'jquery';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})

export class AppComponent implements OnInit {

  public regulamento;
  public is_logged = false;
  public regulamento_indique;
  public perfil = parseInt(localStorage.getItem('perfil'));
  public device = 'mobile';
  private routerSubscription: Subscription;

  constructor( private swUpdate: SwUpdate, private platform: Platform, private router : Router ) {
  }
  
  ngOnInit(): void {

    this.setDevice();

    setTimeout(()=>{ this.checkforUpdate() }, 1000);

    // Capturar os parâmetros UTM da URL
    const utm_source = this.getParameterByName('utm_source');
    const utm_medium = this.getParameterByName('utm_medium');
    const utm_campaign = this.getParameterByName('utm_campaign');

    // Verificar se os parâmetros existem e salvá-los no sessionStorage
    if (utm_source) sessionStorage.setItem('utm_source', utm_source);
    if (utm_medium) sessionStorage.setItem('utm_medium', utm_medium);
    if (utm_campaign) sessionStorage.setItem('utm_campaign', utm_campaign);

    if(localStorage.getItem("userId")){
      this.is_logged = true;
    }

    $(window).on('resize', () => {
      this.handleChanges();
    });

    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.handleChanges();

        if (event instanceof NavigationEnd) {
          this.trackPageView();
        }

        setTimeout(()=>{
          this.definir_css_desktop();
        }, 1000);
      }
    });


  }

  checkforUpdate(){
    
    if (this.swUpdate.isEnabled) {
      this.swUpdate.checkForUpdate().then(isUpdateAvailable => {
        if (isUpdateAvailable) {
          this.swUpdate.activateUpdate().then(() => {
            document.location.reload();
          });
        }
      }).catch(err => {
        console.log("Erro ao verificar atualizações manualmente:", err);
      });
    }
    else{
      console.log('update disabled');
    }


  }

  getParameterByName(name) {
    const url = window.location.href;
    const nameEscaped = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + nameEscaped + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  trackPageView() {
    setTimeout(()=>{
      if (typeof (window as any).mt !== 'undefined') {
        (window as any).mt('send', 'pageview');
      }
    }, 400);
  }

  definir_css_desktop(){

    $(document).ready(function() {
      $('#content').each(function() {

        if ($(this).find('.timeline-desk').length > 0) {
          // Faça algo com o elemento pai que contém o elemento filho
          $(this).addClass('content-with-timeline');
        }
      });
    });

  }

  initializeApp() {
    this.platform.ready().then(() => {

      setTimeout(() => {
        const splash = document.getElementById('splash-screen');
        if (splash) {
          splash.style.display = 'none';
        }
      }, 30000); // Tempo ajustável

    });
  } 

  abrir_regulamento(){
    this.regulamento = true;
  }

  abrir_regulamento_indique(){
    this.regulamento_indique = true;
  }

  logout(){

    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('foto_src');
    localStorage.removeItem('legado');
    localStorage.removeItem('perfil');
    localStorage.removeItem('admin');
    localStorage.removeItem('userName');
    localStorage.removeItem('userName0');
    localStorage.removeItem('userId0');
    localStorage.removeItem('token0');
    localStorage.removeItem('perfil0');
    localStorage.removeItem('foto_src0');
    localStorage.removeItem('isEmbaixador');

    

    setTimeout(()=>{
      this.router.navigate(['/login']).then(()=> { location.reload() });
    }, 500);

  }

  fechar_menu(){
    let menu =  $('ion-menu');
    menu.removeClass('show-menu');
    let backdrop =  $('ion-backdrop');
    backdrop.removeClass('show-backdrop');
  }

  setDevice(): void {

    setTimeout(()=>{

      let currentRoute = this.router.url.split('/').pop().replace('-desktop', '');
      let width = window.innerWidth;
      let mobileRoute = '/' + currentRoute;
      let desktopRoute;
      let isDesktopRoute = this.router.url.includes('-desktop');

      if(currentRoute){


        const indexSegundaBarra = currentRoute.indexOf('/', 1); // Começa a busca após o primeiro caractere

        // Verifique se a segunda barra existe
        if (indexSegundaBarra !== -1) {
          // Insira "-desktop" antes da segunda barra
          const parte1 = currentRoute.substring(0, indexSegundaBarra);
          const parte2 = currentRoute.substring(indexSegundaBarra);
          desktopRoute =  parte1 + "-desktop" + parte2;
        }
        else{
          desktopRoute = currentRoute+'-desktop';
        }
  
        if(isDesktopRoute){
          this.device = 'desktop';
        }
     
        if (width > 780 && !isDesktopRoute && !currentRoute.includes('login')) {
          this.device = 'desktop';
          this.router.navigate([desktopRoute]);
        }
        else if (isDesktopRoute && width < 780){
          this.device = 'mobile';
          this.router.navigate([mobileRoute]);
        }
        
      }

    }, 800);
  } 
  
  handleChanges() {
    setTimeout(() => {
      let isDesktopRoute = this.router.url.includes('-desktop');
      let currentRoute = this.router.url.replace('-desktop', '');
      let routeDesktop;
  
      if(currentRoute){
        const indexSegundaBarra = currentRoute.indexOf('/', 1); // Começa a busca após o primeiro caractere
  
        // Verifique se a segunda barra existe
        if (indexSegundaBarra !== -1) {
          // Insira "-desktop" antes da segunda barra
          const parte1 = currentRoute.substring(0, indexSegundaBarra);
          const parte2 = currentRoute.substring(indexSegundaBarra);
          routeDesktop =  parte1 + "-desktop" + parte2;
        }
        else{
          routeDesktop = currentRoute+'-desktop';
        }
  
        if ($(window).width() < 780 && isDesktopRoute) {
          this.device = 'mobile';
          this.router.navigate([currentRoute]);
        } else if ($(window).width() > 780 && !isDesktopRoute) {
          this.device = 'desktop';
          this.router.navigate([routeDesktop]);
        }
      }
  
    }, 500);
  }

  

}
