import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RoletaService {

  private apiUrl = environment.API_URL.concat('roleta/rodar'); 
  private apiStatusUrl = environment.API_URL.concat('roleta/status'); 

  constructor(private http: HttpClient) { }

  rodarRoleta(id_participante: string): Observable<any> {
    return this.http.post<any>(this.apiUrl, { id_participante }); 
  }


  verificarStatusRoleta(id_participante: string): Observable<any> {
    return this.http.post<any>(this.apiStatusUrl, { id_participante });
  }

}
