import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';


@Component({
  selector: 'app-header-user-perfil',
  templateUrl: './header-user-perfil.component.html',
  styleUrls: ['./header-user-perfil.component.scss'],
  animations: [
    trigger('followState', [
      state('notFollowing', style({
        backgroundColor: '#dc3545',
        color: '#fff',
        borderColor: '#dc3545',
      })),
      state('following', style({
        backgroundColor: '#fff',
        color: '#dc3545',
        borderColor: '#dc3545',
      })),
      transition('notFollowing <=> following', [
        animate('0.5s')
      ]),
    ]),
  ],
})


export class HeaderUserPerfilComponent implements OnInit {
  @Input() userName: string = '';   
  @Input() userPhoto: string = '';  
  @Input() totalPosts: number = 0;  
  @Input() totalSeguidores: number = 0;
  
  @Input() isFollowing: boolean = false; 
  @Input() isCurrentUser: boolean = false;
  @Input() isEmbaixador: number = 0;

  @Output() userClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() followClicked: EventEmitter<void> = new EventEmitter<void>();

  onUserClick() {
    this.userClicked.emit(); 
  }

  onFollowClick() {
    this.followClicked.emit(); 
  }

  constructor() { }

  ngOnInit() {}

}
