import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-extrato',
  templateUrl: './extrato.component.html',
  styleUrls: ['./extrato.component.scss'],
})
export class ExtratoComponent implements OnInit {

  user_id;
  user_name;
  pontos;

  extrato:any = [];
  filtro = { data_inicio : null, data_fim : null};

  constructor(private router:Router, private http: HttpClient) { }

  ngOnInit() {

    this.user_id = localStorage.getItem('userId');
    this.user_name = localStorage.getItem('userName');
    
    if(!this.user_id){
      this.router.navigate(['/login']);
    }

    this.obter_dados();
    this.counter_bonus();
    this.obter_extrato();
  }

  obter_dados(){
    
    this.http.get<any>(environment.API_URL.concat('pontos_iclub?id_participante='+this.user_id)).subscribe(data => {
      this.pontos = data.pontos;
    });
    
  }

  obter_extrato(){

    let url = 'pontos/obter_extrato/'+this.user_id+'?';

    if(this.filtro.data_inicio){
      url = url+'data_inicio='+this.filtro.data_inicio+'&';
    }
    if(this.filtro.data_fim){
      url = url+'data_fim='+this.filtro.data_fim;
    }

    this.http.get<any>(environment.API_URL.concat(url)).subscribe( data => {
      
      if( data.status == 1 ){
    
        this.extrato = data.extrato;
        console.log(this.extrato);

        for(let i =0; this.extrato.length < 0; i++){
          this.extrato[i]['data'] = new Date(this.extrato[i].data);
        }

      }
      else{
        alert("Nenhum registro encontrado");
        console.log(data);
      }

    })
  }

  counter_bonus(){
    let count_navigate = parseInt(localStorage.getItem('count_navigate')) + 1;
    localStorage.setItem('count_navigate', String(count_navigate));
  }






}
