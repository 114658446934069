import { Component, Injectable, OnInit, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import * as $ from 'jquery';

@Component({
  selector: 'app-placar',
  templateUrl: './placar.component.html',
  styleUrls: ['./placar.component.scss'],
}) @Injectable()

export class PlacarComponent implements OnInit {

  constructor(private modalController: ModalController, private route: ActivatedRoute, private router:Router) { }
 
  @Input() isOpen = true;
  @Output() ganhou = new EventEmitter();
  
  @Input() moedas_saldo;
  novo_saldo_moedas;
  @Input() moedas;
  moedas_anima;

  @Input() pontos_saldo;
  novo_saldo_pontos;
  @Input() pontos;
  pontos_anima;

  ngOnInit() {
      this.novo_saldo_moedas = this.moedas_saldo;
      this.novo_saldo_pontos = this.pontos_saldo;

      this.receber_premio();
  }

  receber_premio(){

    let finish_pontos = false;
    let finish_moedas = false;
    
    const variante_pontos = 0.9;
    const variante_moedas = 0.9;

    const audioWin = new Audio('assets/audio/audioCoin.mpeg');
    audioWin.play();

    let novo_saldo_moedas = parseInt(this.moedas_saldo ?? 0) + parseInt(this.moedas ?? 0);
    this.moedas_anima = this.moedas ?? 0;

    let earning = setInterval(()=>{

      if(this.moedas_anima > 0.1){
        this.novo_saldo_moedas += variante_moedas;
        this.moedas_anima -= variante_moedas;
      }
      else{
        this.moedas_anima = 0;
        this.novo_saldo_moedas = novo_saldo_moedas;
        $(".box_ganho_moedas").fadeOut(300);
        clearInterval(earning);

        finish_moedas = true;
      }
    }, 20);

    let novo_saldo_pontos = parseInt(this.pontos_saldo ?? 0) + parseInt(this.pontos ?? 0);
    this.pontos_anima = this.pontos ?? 0;
    
    let earning_pontos = setInterval(()=>{

      if(this.pontos_anima > 0.1){
        this.novo_saldo_pontos += variante_pontos;
        this.pontos_anima -= variante_pontos;
      }
      else{
        this.pontos_anima = 0;
        this.novo_saldo_pontos = novo_saldo_pontos;
        $(".box_ganho_pontos").fadeOut(300);
        clearInterval(earning_pontos);

        finish_pontos = true;

      }
    }, 20);
    
    let finalizar = setInterval(()=>{
      if(finish_moedas && finish_pontos){
        $("#novo_saldo").fadeIn(200);
        this.ganhou.emit({moedas: novo_saldo_moedas, pontos: novo_saldo_pontos});
        clearInterval(finalizar);
      }
    }, 500);

  }

  


}
