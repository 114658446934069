import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { IonSlides } from '@ionic/angular';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {

  @ViewChild('slides') slides: IonSlides;
  perfil = parseInt(localStorage.getItem('perfil'));
  user_id: string;
  user_name = localStorage.getItem('userName');
  foto_src = "https://cdn-icons-png.flaticon.com/512/149/149071.png";
  grupos = [];
  pontos = parseInt(localStorage.getItem('pontos')) || 0;

  progress = {
    certificados: 0,
    porcentagem: 0
  };

  paginas = [
    { title: 'Descontos exclusivos', subtitle: 'Grandes marcas parceiras para você economizar!', img: '/assets/personagem-iclub.png', link: 'descontos' },
    { title: 'Sorteios', subtitle: 'Sorteios de prêmios incríveis!', img: 'assets/img/icone_sorteios.png', link: 'sorteios' },
  ];

  sliderOptions = {
    slidesPerView: 1,
    spaceBetween: 10,
    dots: true,
    autoplay: {
      delay: 6000,
      disableOnInteraction: false,
    },
    pagination: { // Configurações da paginação (dots)
      el: '.swiper-pagination', // Elemento HTML que irá conter os dots
    },
  };

  banners: any[];

  @Input() pagina = 'home';
  @Input() aniversario = false;
  @Input() banner: any;
  @Output() regulamento = new EventEmitter();

  constructor(private http: HttpClient, private elementRef: ElementRef, private router: Router) {}

  ngOnInit() {
    if (window.innerWidth >= 780) { // Verifica se é desktop
      this.carregarDadosUsuario();
      this.obter_banners_slide();
      
      // Escuta o evento de navegação para atualizar os dados
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.carregarDadosUsuario();
        }
      });
    }
  }

  private carregarDadosUsuario(): void {
    // Carrega dados do usuário e realiza atualizações
    if (localStorage.getItem('foto_src')) {
      this.foto_src = localStorage.getItem('foto_src');
    }
    if (localStorage.getItem('aniversariante')) {
      this.aniversario = true;
    }
    this.user_id = localStorage.getItem('userId');
    this.obter_progresso();
    this.obter_grupos();
    this.loadPoints();
    setTimeout(() => {
      this.set_scroll();
    }, 2000);
  }

  obter_progresso(): void {
    const formData = new FormData();
    formData.append('id_participante', this.user_id);

    this.http.post<any>(environment.API_URL.concat('participantes_iclub/obter_progresso/' + this.user_id), formData).subscribe(
      data => {
        if (data.status === 1) {
          this.progress = data.progresso;
        }
      },
      error => console.error('Erro ao obter progresso:', error)
    );
  }

  private async loadPoints(): Promise<void> {
    try {
      const response: any = await this.http.get<any>(
        `${environment.API_URL}pontos_iclub/index?id_participante=${this.user_id}`
      ).toPromise();
      this.pontos = response.pontos || 0;
      localStorage.setItem('pontos', String(this.pontos));
    } catch (error) {
      console.error('Erro ao obter pontos:', error);
    }
  }

  obter_grupos(): void {
    const formData = new FormData();
    formData.append('id_participante', this.user_id);

    this.http.post<any>(environment.API_URL.concat('sociais_iclub/obter_grupos'), formData).subscribe(
      data => {
        if (data.status === 1) {
          this.grupos = data.grupos;
          this.set_scroll();
        }
      },
      error => console.error('Erro ao obter grupos:', error)
    );
  }

  obter_banners_slide(): void {
    this.http.get(environment.API_URL.concat('acoes_iclub/obter_banners_slide')).subscribe(
      (data: any) => {
        if (data.status === 1) {
          this.banners = data.banners;
        } else {
          console.log(data);
        }
      },
      error => console.error('Erro ao obter banners:', error)
    );
  }

  abrir_regulamento(): void {
    this.regulamento.emit({ abrir: true });
  }

  set_scroll(): void {
    const element = document.getElementsByClassName('bg-gray')[0];
    element.addEventListener('scroll', () => {
      const scrollTop = element.scrollTop;
      const targetPosition = 1900;

      if (scrollTop > targetPosition) {
        this.fixElement();
      } else {
        this.unfixElement();
      }
    });
  }

  fixElement(): void {
    const fixedElement = document.getElementById('sidebar');
    fixedElement.classList.add('fixed');
  }

  unfixElement(): void {
    const fixedElement = document.getElementById('sidebar');
    fixedElement.classList.remove('fixed');
  }
}
