import { Component, EventEmitter, OnInit, Output  } from '@angular/core';
import { RoletaService } from 'src/app/services/roleta.service';
import { AlertController } from '@ionic/angular';

@Component({
  selector: 'app-roleta',
  templateUrl: './roleta.component.html',
  styleUrls: ['./roleta.component.scss'],
})
export class RoletaComponent implements OnInit {

  @Output() fecharModal = new EventEmitter<void>(); 

  is_open: boolean = false;

  public pontosGanhos: number | null = null;
  public rodando = false;
  public anguloAtual = 0;
  public user_id: string | null = null;
  public exibirPremio = false; // Controla a exibição do prêmio e das animações de sumiço
  public mostrarPremio = false; // Controla a exibição do GIF e da imagem dos pontos

  public premios = [
    { pontos: 50,   angulo: 90 },    
    { pontos: 1000, angulo: 235 }, 
    { pontos: 500,  angulo: 195 },  
    { pontos: 200,  angulo: 160 },  
    { pontos: 100,  angulo: 30 }
  ];

  public tempoRestante: string | null = null;
  public exibirTimer: boolean = false;

  private roletaAudio = new Audio('assets/roleta.mp3');
  private coinsAudio = new Audio('assets/coins.mp3');

  constructor(private roletaService: RoletaService, private alertController: AlertController) { }

  ngOnInit() {
    this.definirDadosUsuario();
    this.verificarStatusRoleta();
  }

  private definirDadosUsuario() {
    this.user_id = localStorage.getItem('userId');
  }

  verificarStatusRoleta() {
    if (!this.user_id) return;

    this.roletaService.verificarStatusRoleta(this.user_id).subscribe(response => {
      if (response.status === 'error' && response.tempoRestante) {
        this.exibirTimer = true;
        this.tempoRestante = response.tempoRestante;
        this.iniciarContagemRegressiva(response.tempoRestante);
      } else {
        this.exibirTimer = false;
      }
    });
  }

  iniciarContagemRegressiva(tempoRestante: string) {
    const partes = tempoRestante.split(':');
    let horas = parseInt(partes[0], 10);
    let minutos = parseInt(partes[1], 10);
    let segundos = parseInt(partes[2], 10);
  
    const intervalo = setInterval(() => {
      if (segundos > 0) {
        segundos--;
      } 
      else {
        if (minutos > 0) {
          minutos--;
          segundos = 59;
        } else if (horas > 0) {
          horas--;
          minutos = 59;
          segundos = 59;
        } else {
          clearInterval(intervalo);
          this.exibirTimer = false; // Quando o tempo acabar, escondemos o timer e habilitamos o botão
          return;
        }
      }
      this.tempoRestante = `${this.padZero(horas)}:${this.padZero(minutos)}:${this.padZero(segundos)}`;
    }, 1000);
  }
  
  padZero(valor: number): string {
    return valor < 10 ? `0${valor}` : `${valor}`;
  }

  rodar() {
    if (this.rodando || !this.user_id) return;

    this.rodando = true;
    this.exibirPremio = false; 
    this.mostrarPremio = false; 

    this.roletaAudio.play();

    this.roletaService.rodarRoleta(this.user_id).subscribe(response => {
      if (response.status === 'success') {
        const pontosSorteados = response.pontos;

        const voltasCompletas = 360 * 7;
        const anguloFinal = voltasCompletas + this.getAnguloPorPontos(pontosSorteados); 
        this.anguloAtual = anguloFinal;

        const roletaImg = document.querySelector('.imagem-roleta') as HTMLElement;
        roletaImg.style.transform = `rotate(${this.anguloAtual}deg)`;

        setTimeout(() => {
          this.rodando = false;
          this.pontosGanhos = pontosSorteados;

          this.roletaAudio.pause();
          this.roletaAudio.currentTime = 0;

          this.exibirPremio = true;

          setTimeout(() => {
            this.mostrarPremio = true;
            this.coinsAudio.play();
          }, 1200); 

        }, 5000); // Tempo de rotação da roleta
      } else {
        this.rodando = false;
        this.mostrarAlerta(response.message);

        if (response.tempoRestante) {
          this.tempoRestante = response.tempoRestante;
          this.exibirTimer = true; // Controla a exibição do timer
        }
      }
    });
  }

  getAnguloPorPontos(pontos: number): number {
    const premiosFiltrados = this.premios.filter(p => p.pontos === pontos);
    const premio = premiosFiltrados[Math.floor(Math.random() * premiosFiltrados.length)];
    return premio ? premio.angulo : 0;
  }

  // Função para retornar a imagem correta para cada prêmio
  getImageForPrize(pontos: number): string {
    switch (pontos) {
      case 1000:
        return 'assets/1000.png';
      case 500:
        return 'assets/500.png';
      case 200:
        return 'assets/200.png';
      case 100:
        return 'assets/100.png';
      case 50:
        return 'assets/50.png';
      default:
        return 'assets/roleta.png'; // Imagem padrão, caso algo dê errado
    }
  }

  // Lógica para redirecionar para a vitrine
  irParaVitrine() {
    window.location.href = '/vitrine';
  }

  async mostrarAlerta(mensagem: string) {
    const alert = await this.alertController.create({
      header: 'Aviso',
      message: mensagem,
      buttons: ['OK']
    });
    await alert.present();
  }

}
