import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-notification-permission',
  templateUrl: './notification-permission.component.html',
  styleUrls: ['./notification-permission.component.scss'],
})
export class NotificationPermissionComponent implements OnInit {

  notificationDenied: boolean = false;
  showPermissionBox: boolean = false;
  isNotificationSupported: boolean = false;

  constructor() {}

  ngOnInit(): void {
    this.checkNotificationSupport();
    if (this.isNotificationSupported) {
      const permission = Notification.permission;
      if (permission === 'default' || permission === 'denied') {
        this.notificationDenied = true;
      }
    }
  }

  checkNotificationSupport(): void {
    // Verifica se o navegador suporta notificações
    this.isNotificationSupported = 'Notification' in window;
  }

  togglePermissionBox(): void {
    if (!this.isNotificationSupported) {
      console.warn('Notificações não são suportadas neste dispositivo/navegador.');
      return;
    }
    this.showPermissionBox = !this.showPermissionBox;
  }

  requestPermission(): void {
    if (!this.isNotificationSupported) {
      console.warn('Notificações não são suportadas neste dispositivo/navegador.');
      return;
    }

    Notification.requestPermission().then(permission => {
      if (permission === 'granted') {
        this.notificationDenied = false;
        this.showPermissionBox = false;
        console.log('Notificações permitidas.');
      } else if (permission === 'denied') {
        console.log('Permissão para notificações negada.');
      }
    }).catch(err => {
      console.error('Erro ao solicitar permissão de notificação:', err);
    });
  }
}
