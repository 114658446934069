import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { ProdutoModalComponent } from 'src/app/includes/produto-modal/produto-modal.component';
import { ToastController } from '@ionic/angular';

@Component({
  selector: 'app-vitrine',
  templateUrl: './vitrine.component.html',
  styleUrls: ['./vitrine.component.scss'],
})
export class VitrineComponent implements OnInit {


  user_id;
  user_name;
  foto_src = "https://cdn-icons-png.flaticon.com/512/149/149071.png";
  pontos = localStorage.getItem('pontos') || 0;
  
  perfil = parseInt(localStorage.getItem('perfil'));
  posicao;
  progress: number = 30;

  vitrineAtiva: boolean = true;

  produtos = [];

  produtosResgatados = []

  ranking:any = [];


  is_desktop = false;

  data = new Date();
  regulamento = false;
  modal = false;

  produtoDisponivel ? : boolean;

  modalRastreio: boolean = false;

  rastreioAtual: string;

  isIOS(): boolean {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream;
  }
  
  constructor( private http:HttpClient, private router:Router, private location:Location, private route: ActivatedRoute, public dialog: MatDialog,  private toastController: ToastController ) { }

  ngOnInit() {
    this.user_name = localStorage.getItem('userName');
    this.user_id = localStorage.getItem('userId');

    if(localStorage.getItem('foto_src')){
      this.foto_src = localStorage.getItem('foto_src');
    }

    if(!this.user_id){
     this.router.navigate(['/login']);
    }
    
    this.loadPoints();
    this.definirAmbiente();
    this.obter_produtos();
    this.obter_produtos_resgatados();
  }

  mostrarVitrine(): void {
    this.vitrineAtiva = true;
  }
  mostrarPedidos(): void {
    this.obter_produtos_resgatados();
    this.vitrineAtiva = false;
  }

  private definirAmbiente() {
    const rota: string = this.route.snapshot.url.toString();
    this.is_desktop = rota.includes('desktop');
  }



  private async loadPoints(): Promise<void> {
    try {
      const response = await this.http.get<any>(
        `${environment.API_URL}pontos_iclub/index?id_participante=${this.user_id}`
      ).toPromise();

      this.pontos = response.pontos || 0;
      localStorage.setItem('pontos', response.pontos);
      
    } catch (error) {
      console.error('Erro ao obter pontos:', error);
    }
  }


  /*Produtos  */

  obter_produtos(){
    this.http.get(environment.API_URL.concat('vitrine_iclub/obter_produtos')).subscribe((data: any) => {
      this.produtos = data;
      //console.log('Produtos:', this.produtos);
    });
  }

  obter_produtos_resgatados(){
    this.http.get(environment.API_URL.concat('vitrine_resgate_iclub/resgates_usuario/'+this.user_id)).subscribe(
      (data: any) => {
        this.produtosResgatados = data;
        //console.log('Produtos resgatados:', this.produtosResgatados);
      },
      (error) => {
        console.error('Erro:', error);
      }
    );
  }

  abrirModalProduto(produto): void {
    const dialogRef = this.dialog.open(ProdutoModalComponent, {
      width: '500px',
      data: produto
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed');
    });
  }

  /* Rastreio */
  abrirModalRastreio(rastreio: string): void {
    this.rastreioAtual = rastreio; 
    this.modalRastreio = true;
  }
  
  fecharModalRastreio(): void {
    this.modalRastreio = false; 
    this.rastreioAtual = ''; 
  }

  copiarRastreio(rastreio: string): void {
    navigator.clipboard.writeText(rastreio).then(() => {
      this.notify('Código copiado! Redirecionando para os Correios...').then(() => {
        setTimeout(() => {
          window.open('https://www2.correios.com.br/sistemas/rastreamento/', '_blank');
        }, 5000);
      });
    }).catch(err => {
      console.error('Erro ao copiar o código:', err);
      this.notify('Falha ao copiar o código. Por favor, tente novamente.');
    });
  }

  abrir_regulamento(){
    this.regulamento = true;
  }

  goback(){
    this.location.back();
  }

  async notify(mensagem: string) {
    const toast = await this.toastController.create({
      message: mensagem,
      duration: 5000,
      position: 'middle', 
      cssClass: 'toast-css',
      color: 'dark',
      mode: 'md',
    });
    toast.present();
  }
  
} 
